// candidateConstants.js
export const CANDIDATE_CREATE_REQUEST = 'CANDIDATE_CREATE_REQUEST';
export const CANDIDATE_CREATE_SUCCESS = 'CANDIDATE_CREATE_SUCCESS';
export const CANDIDATE_CREATE_FAIL = 'CANDIDATE_CREATE_FAIL';
export const CANDIDATE_CREATE_RESET = 'CANDIDATE_CREATE_RESET';

export const CANDIDATE_RETRIEVE_REQUEST = 'CANDIDATE_RETRIEVE_REQUEST';
export const CANDIDATE_RETRIEVE_SUCCESS = 'CANDIDATE_RETRIEVE_SUCCESS';
export const CANDIDATE_RETRIEVE_FAIL = 'CANDIDATE_RETRIEVE_FAIL';
export const CANDIDATE_RETRIEVE_RESET = 'CANDIDATE_RETRIEVE_RESET';

export const CANDIDATE_LIST_REQUEST = 'CANDIDATE_LIST_REQUEST';
export const CANDIDATE_LIST_SUCCESS = 'CANDIDATE_LIST_SUCCESS';
export const CANDIDATE_LIST_FAIL = 'CANDIDATE_LIST_FAIL';
export const CANDIDATE_LIST_RESET = 'CANDIDATE_LIST_RESET';
