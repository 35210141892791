// organizationConstants.js
export const ORGANIZATION_CREATE_REQUEST = 'ORGANIZATION_CREATE_REQUEST';
export const ORGANIZATION_CREATE_SUCCESS = 'ORGANIZATION_CREATE_SUCCESS';
export const ORGANIZATION_CREATE_FAIL = 'ORGANIZATION_CREATE_FAIL';
export const ORGANIZATION_CREATE_RESET = 'ORGANIZATION_CREATE_RESET';

export const ORGANIZATION_UPDATE_REQUEST = 'ORGANIZATION_UPDATE_REQUEST';
export const ORGANIZATION_UPDATE_SUCCESS = 'ORGANIZATION_UPDATE_SUCCESS';
export const ORGANIZATION_UPDATE_FAIL = 'ORGANIZATION_UPDATE_FAIL';
export const ORGANIZATION_UPDATE_RESET = 'ORGANIZATION_UPDATE_RESET';

export const ORGANIZATION_RETRIEVE_REQUEST = 'ORGANIZATION_RETRIEVE_REQUEST';
export const ORGANIZATION_RETRIEVE_SUCCESS = 'ORGANIZATION_RETRIEVE_SUCCESS';
export const ORGANIZATION_RETRIEVE_FAIL = 'ORGANIZATION_RETRIEVE_FAIL';
export const ORGANIZATION_RETRIEVE_RESET = 'ORGANIZATION_RETRIEVE_RESET';

export const ORGANIZATION_LIST_REQUEST = 'ORGANIZATION_LIST_REQUEST';
export const ORGANIZATION_LIST_SUCCESS = 'ORGANIZATION_LIST_SUCCESS';
export const ORGANIZATION_LIST_FAIL = 'ORGANIZATION_LIST_FAIL';
export const ORGANIZATION_LIST_RESET = 'ORGANIZATION_LIST_RESET';
