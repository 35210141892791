import React, {useEffect, useState} from 'react';
import {Link, useLocation, useNavigate} from "react-router-dom";
import {Avatar, AvatarFallback, AvatarImage} from "src/components/ui/avatar";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator,
  DropdownMenuTrigger
} from "src/components/ui/dropdown-menu";
import {Popover, PopoverContent, PopoverTrigger} from "src/components/ui/popover";
import {Button} from "src/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem, CommandList,
  CommandSeparator
} from "src/components/ui/command";
import {ArrowUpCircle, Check, CheckCircle2, ChevronsUpDown, Circle, Copy, HelpCircle, XCircle} from "lucide-react"
import {cn} from "src/lib/utils";
import {DATA} from "src/data/data";
import {Label} from "src/components/ui/label";
import {Input} from "src/components/ui/input";
import {useSelector} from "react-redux";


function OrganizationSelector(props) {
  const { organizations } = props

  const [open, setOpen] = React.useState(false)
  const [value, setValue] = React.useState(organizations.find(org => org.is_default).id)

  // useEffect(() => {
  //   setValue(organizations.find(org => org.is_default)?.id)
  // }, [organizations]);


  return (
    <Popover className='h-full my-auto' open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="tertiary"
          role="combobox"
          aria-expanded={open}
          className="w-[150px] md:w-[200px] justify-between border-[1px] outline-none my-auto"
        >
          {value
            ? organizations.find((org) => org.id === value).name
            : "Select Organization..."}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0 dark">
        <Command filter={(value, search) => {
          return organizations.find(org => org.id === value && org.name.toLowerCase().includes(search.toLowerCase())) ? 1 : 0;
        }}>
          <CommandInput placeholder="Search organizations..." />
          <CommandList>
            <CommandEmpty>No organization found.</CommandEmpty>

            <CommandGroup heading='Organizations'>
              {organizations.map(org => (
                <CommandItem
                  className='cursor-pointer text-!grey-200'
                  key={org.id}
                  value={org.id}
                  onSelect={currentValue => {
                    setValue(organizations.find(o => o.id === currentValue).id);
                    setOpen(false);
                  }}
                >
                  <Check
                    className={cn(
                      "mr-2 h-4 w-4",
                      value === org.id ? "opacity-100" : "opacity-0"
                    )}
                  />
                  <span>{org.name}</span>
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}


const menuItems = [
  {
    label: 'Home',
    link: ''
  },
  {
    label: 'Interviews',
    link: '/interviews'
  },
  {
    label: 'Organization',
    link: '/organizations'
  },
  {
    label: 'Billing',
    link: '/organizations/billing'
  },
  // {
  //   label: 'Members',
  //   link: '/organizations/members'
  // },
  {
    label: 'Settings',
    link: '/settings'
  },
]

function Header(props) {
  // const user = DATA.user;

  const { user } = useSelector(state => state.retrieveUser);

  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className={cn(
      'h-[10vh] w-full md:pr-20 sm:pr-12 pr-8 py-4 z-50 justify-between border-b-[1px] border-!grey-200/20 bg-!black',
      props.className
    )}>
      <Link to='/' className='flex flex-row cursor-pointer z-50 md:w-[250px] w-[100px]'>
        <img className='sm:h-[5vh] h-[4vh] m-auto' src='/app/media/images/logo/supermodal-md.webp' alt='logo'/>
      </Link>

      <div className='flex flex-row h-full my-auto gap-x-14'>
        <OrganizationSelector organizations={user.organizations} />

        <DropdownMenu>
          <DropdownMenuTrigger className='outline-none'>
            <Avatar className='sm:h-[5vh] sm:w-[5vh] h-[4vh] w-[4vh] my-auto'>
              {/*<AvatarImage src="https://github.com/shadcn.png" alt="@shadcn" />*/}
              <AvatarImage src="/app/media/images/illustrations/profile-pic-2.webp" alt="@cognatoai" />
              <AvatarFallback>
                {
                  user.name ? (
                    user.name.slice(0, 2).toUpperCase()
                  ) : (
                    user.email.slice(0, 2).toUpperCase()
                  )
                }
              </AvatarFallback>
            </Avatar>
          </DropdownMenuTrigger>
          <DropdownMenuContent className='dark' align='end'>
            <DropdownMenuLabel className='text-white px-28 pl-2'>
              <span className='font-bold'>My Account</span><br />
              <span className='font-normal text-white/70 text-[12px]'>{user.email}</span>
            </DropdownMenuLabel>
            <DropdownMenuSeparator className='text-!grey-200 bg-!grey-200/20' />
            {
              menuItems.map((item) => (
                <DropdownMenuItem
                  key={item.label}
                  className='cursor-pointer'
                  onSelect={() => navigate(item.link)}
                >
                  {item.label}
                </DropdownMenuItem>
              ))
            }
            <DropdownMenuSeparator className='text-!grey-200 bg-!grey-200/20' />
            <DropdownMenuItem
              className='cursor-pointer text-!red-600 focus:bg-!red-600 focus:text-white'
              onSelect={() => navigate('/logout')}
            >
              Logout
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  );
}

export default Header;