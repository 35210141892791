export const REVIEW_CREATE_REQUEST = 'REVIEW_CREATE_REQUEST';
export const REVIEW_CREATE_SUCCESS = 'REVIEW_CREATE_SUCCESS';
export const REVIEW_CREATE_FAIL = 'REVIEW_CREATE_FAIL';
export const REVIEW_CREATE_RESET = 'REVIEW_CREATE_RESET';

export const REVIEW_RETRIEVE_REQUEST = 'REVIEW_RETRIEVE_REQUEST';
export const REVIEW_RETRIEVE_SUCCESS = 'REVIEW_RETRIEVE_SUCCESS';
export const REVIEW_RETRIEVE_FAIL = 'REVIEW_RETRIEVE_FAIL';
export const REVIEW_RETRIEVE_RESET = 'REVIEW_RETRIEVE_RESET';

export const REVIEW_LIST_REQUEST = 'REVIEW_LIST_REQUEST';
export const REVIEW_LIST_SUCCESS = 'REVIEW_LIST_SUCCESS';
export const REVIEW_LIST_FAIL = 'REVIEW_LIST_FAIL';
export const REVIEW_LIST_RESET = 'REVIEW_LIST_RESET';