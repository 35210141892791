import React from 'react';
import {cn} from "src/lib/utils";

function Content(props) {
  return (
    <div className={cn(
      'h-full w-full',
      props.className
    )}>
      {props.children}
    </div>
  );
}

export default Content;