import React from 'react';
import { Alert as BaseAlert, AlertTitle, AlertDescription } from 'src/components/ui/alert';
import {Rocket, TriangleAlert} from 'lucide-react';
import {cn} from "src/lib/utils";

function Alert(props) {
  return (
    <BaseAlert variant={props.variant}  className={cn(props.className)} {...props}>
      {
        props.variant === 'danger' ? (
          <TriangleAlert className="h-4 w-4" />
        ) : (
          <Rocket className="h-4 w-4" />
        )
      }
      <AlertTitle>{props.title}</AlertTitle>
      <AlertDescription>
        {props.description}
      </AlertDescription>
    </BaseAlert>
  )
}

export default Alert;