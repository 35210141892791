// interviewActions.js
import {
  INTERVIEW_CREATE_REQUEST,
  INTERVIEW_CREATE_SUCCESS,
  INTERVIEW_CREATE_FAIL,
  INTERVIEW_CREATE_RESET,

  INTERVIEW_RETRIEVE_REQUEST,
  INTERVIEW_RETRIEVE_SUCCESS,
  INTERVIEW_RETRIEVE_FAIL,
  INTERVIEW_RETRIEVE_RESET,

  INTERVIEW_LIST_REQUEST,
  INTERVIEW_LIST_SUCCESS,
  INTERVIEW_LIST_FAIL,
  INTERVIEW_LIST_RESET,

  INTERVIEW_RESULT_DOWNLOAD_REQUEST,
  INTERVIEW_RESULT_DOWNLOAD_SUCCESS,
  INTERVIEW_RESULT_DOWNLOAD_FAIL,
  INTERVIEW_RESULT_DOWNLOAD_RESET,
} from './interviewConstants';
import { INTERVIEW_APIS } from 'src/apis/interviewApis';
import { UTILS } from 'src/commons/utils';
import {CONFIG} from "src/commons/config";
import {ORGANIZATION_RETRIEVE_SUCCESS} from "src/store/organization/organizationConstants";

export const createInterview = (data) => async (dispatch, getState) => {
  try {
    dispatch({
      type: INTERVIEW_CREATE_REQUEST
    });

    const token = UTILS.getToken();

    const configs = {
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": `Bearer ${token}`,
        "x-organization": UTILS.getOrganization(),
      }
    };

    let response = await INTERVIEW_APIS.create(data, configs);
    response = response.data;

    if (response && response.error) {
      throw response;
    }

    dispatch({
      type: INTERVIEW_CREATE_SUCCESS,
      payload: response
    });

    const retrieveOrganization = getState();
    if(retrieveOrganization) {
      let { organization } = retrieveOrganization;
      if(organization) {
        organization = {
          ...organization,
          credits: organization.credits - CONFIG.INTERVIEW_COST
        }

        dispatch({
          type: ORGANIZATION_RETRIEVE_SUCCESS,
          payload: organization,
        })
      }
    }
  }
  catch (error) {
    dispatch({
      type: INTERVIEW_CREATE_FAIL,
      payload: error.response ? error.response.data : {
        error: true,
        message: error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      }
    });
  }
};

export const retrieveInterview = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: INTERVIEW_RETRIEVE_REQUEST
    });

    const token = UTILS.getToken();

    const configs = {
      headers: {
        "Authorization": `Bearer ${token}`,
        "x-organization": UTILS.getOrganization(),
      }
    };

    let response = await INTERVIEW_APIS.retrieve(id, configs);
    response = response.data;

    if (response && response.error) {
      throw response;
    }

    dispatch({
      type: INTERVIEW_RETRIEVE_SUCCESS,
      payload: response
    });
  }
  catch (error) {
    dispatch({
      type: INTERVIEW_RETRIEVE_FAIL,
      payload: error.response ? error.response.data : {
        error: true,
        message: error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      }
    });
  }
};

export const listInterviews = (params) => async (dispatch, getState) => {
  try {
    dispatch({
      type: INTERVIEW_LIST_REQUEST
    });

    const token = UTILS.getToken();

    const configs = {
      headers: {
        "Authorization": `Bearer ${token}`,
        "x-organization": UTILS.getOrganization(),
      },
    };

    let response = await INTERVIEW_APIS.list(params, configs);
    response = response.data;

    if (response && response.error) {
      throw response;
    }

    dispatch({
      type: INTERVIEW_LIST_SUCCESS,
      payload: response
    });
  }
  catch (error) {
    dispatch({
      type: INTERVIEW_LIST_FAIL,
      payload: error.response ? error.response.data : {
        error: true,
        message: error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      }
    });
  }
};


export const dowloadInterviewResult = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: INTERVIEW_RESULT_DOWNLOAD_REQUEST
    });

    const token = UTILS.getToken();

    const configs = {
      headers: {
        "Authorization": `Bearer ${token}`,
        "x-organization": UTILS.getOrganization(),
      },
      responseType: 'blob'
    };

    let response = await INTERVIEW_APIS.downloadResult(id, configs);
    response = response.data;

    if (response && response.error) {
      throw response;
    }

    // Create a Blob from the response
    const url = window.URL.createObjectURL(new Blob([response]));

    // Create a temporary anchor element for the download
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${id}.pdf`); // Replace with the desired file name
    document.body.appendChild(link);
    link.click();

    // Cleanup
    link.remove();
    window.URL.revokeObjectURL(url);


    dispatch({
      type: INTERVIEW_RESULT_DOWNLOAD_SUCCESS,
      success: true
    });
  }
  catch (error) {
    dispatch({
      type: INTERVIEW_RESULT_DOWNLOAD_FAIL,
      payload: error.response ? error.response.data : {
        error: true,
        message: error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      }
    });
  }
};
