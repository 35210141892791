import React, {useEffect, useRef, useState} from 'react';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle
} from "src/components/ui/card";

import {
  BriefcaseBusiness,
  ContactRound,
  CalendarDays, Search, Info, Trash2, Plus
} from 'lucide-react';
import Input from "src/components/Input";
import TextArea from "src/components/TextArea";
import {Separator} from "src/components/ui/separator";
import {Checkbox} from "src/components/ui/checkbox";
import {Switch} from "src/components/ui/switch";
import Button from "src/components/Button";
import {UTILS} from "src/commons/utils";
import {cn} from "src/lib/utils";
import toast from "react-hot-toast";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue
} from "src/components/ui/select";
import {DATA} from "src/data/data";
import {Label} from "src/components/ui/label";
import DatePicker from "src/components/DatePicker";
import {useDispatch, useSelector} from "react-redux";
import {createInterview} from "src/store/interview/interviewActions";
import Modal from "src/components/Modal";
import {INTERVIEW_CREATE_RESET} from "src/store/interview/interviewConstants";
import {listCandidates} from "src/store/candidate/candidateActions";
import {Popover} from "@radix-ui/react-popover";
import {PopoverContent, PopoverTrigger} from "src/components/ui/popover";
import NoData from "src/components/NoData";
import Alert from "src/components/Alert";
import {CONFIG} from "src/commons/config";
import {retrieveOrganization} from "src/store/organization/organizationActions";
import {CANDIDATE_LIST_RESET} from "src/store/candidate/candidateConstants";
import { listStages } from 'src/store/stage/stageActions';
import Loader from 'src/components/Loader';


const steps = [
  {
    step: 'job',
    label: 'Job',
    formId: 'jobForm',
    icon: <BriefcaseBusiness />
  },
  {
    step: 'module',
    label: 'Modules',
    formId: 'moduleForm',
    icon: <BriefcaseBusiness />
  },
  {
    step: 'candidate',
    label: 'Candidate',
    formId: 'candidateForm',
    icon: <ContactRound />
  },
  {
    step: 'schedule',
    label: 'Schedule',
    formId: 'scheduleForm',
    icon: <CalendarDays />
  },
]

function JobForm(props) {
  return (
    <form className={cn(
      'flex flex-col',
      props.className
    )}
    id='jobForm'
    {...props}
    >
      <div className='flex flex-row  font-semibold text-lg pb-4'>
        Job
      </div>

      <div className='flex flex-col md:w-[60%] w-full gap-4'>
        <div className='flex flex-row w-full gap-x-4'>
          <div className='flex flex-col w-full'>
            <Input label='id' type='text' placeholder='Job Id' defaultValue='13123' disabled required />
          </div>

          <div className='flex flex-col w-full'>
            <Input label='Role' type='text' placeholder='Role' defaultValue='Software Engineer' disabled required />
          </div>
        </div>

        <div className='flex flex-row w-full gap-x-4'>
          <TextArea label='Description' defaultValue='Responsibilities include managing project priorities, deadlines, and deliverables; designing, developing, testing, deploying, maintaining, and enhancing software solutions. The role requires versatility, leadership qualities, and enthusiasm to tackle new problems across the full stack.' disabled />
        </div>
      </div>
    </form>
  );
}

function ModuleFormV1(props) {
  const dispatch = useDispatch();

  const { loading, error, stages } = useSelector(state => state.listStages)

  useEffect(() => {
    if(error) {
      toast.error(error.message);
    } else if(!stages) {
      dispatch(listStages());
    }

    if(stages) {
      var defaultStages = [];
      stages.data.forEach(stage => {
        if(DATA.defaultStages.indexOf(stage.code) >= 0) {
          defaultStages.push(stage.id)
        }
      })

      props.setSelectedStages(defaultStages)
    }
  }, [error, stages])

  return (
    <form className={cn(
      'flex flex-col',
      props.className
    )}
    id='moduleForm'
    {...props}
    >
      <div className='flex flex-col  font-semibold text-lg pb-4'>
        Module
        <span className='text-[12px] text-!off-white font-normal'>Select the module that you want to interview the candidate on.</span>
      </div>

      <>
        {
          loading ? (
            <Loader />
          ) : (
            <div className='flex flex-col md:w-[80%] w-full gap-2'>
              <div className="items-top flex space-x-2">
                <Checkbox checked disabled id="INTRODUCTION" />
                <div className="grid gap-1.5 leading-none">
                  <label
                    htmlFor="INTRODUCTION"
                    className="text-sm font-normal leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                  >
                    Introduction
                  </label>
                </div>
              </div>

              <div className='grid grid-cols-1 gap-2'>
                {
                  stages?.data.map((stage, index) => (
                    <div key={index} className="items-top flex space-x-2">
                      <Checkbox
                        id={stage.id}
                        value={stage.id}
                        checked={props.selectedStages.indexOf(stage.id) >= 0}
                        onCheckedChange={() => {
                          // console.log('checkbox on change called', stage)
                          props.handleStageSelect(stage.id)
                        }}
                      />
                      <div className="grid gap-1.5 leading-none">
                        <label
                          htmlFor={stage.id}
                          className="text-sm font-normal leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                        >
                          {stage.name}
                        </label>
                      </div>
                    </div>
                  ))
                }
              </div>

              <div className='flex flex-col pt-10 pb-2 text-[12px] text-!grey-200 font-light my-auto list-none'>
                <div className='flex flex-row my-auto'>
                  <Info className='flex flex-col h-[0.8rem] mt-[0.4vh]' />
                  <span className='flex flex-col'>If you have any other technical modules in mind, please write to us at <a className='text-blue-600 hover:text-blue-400' href="mailto:team@cognatoai.com">team@cognatoai.com</a></span>
                </div>
              </div>
            </div>
          )
        }
      </>
    </form>
  );
}

function ModuleForm(props) {
  const { customQuestions } = props;

  const dispatch = useDispatch();

  const { loading, error, stages } = useSelector(state => state.listStages);
  // const [customQuestions, setCustomQuestions] = React.useState(props.customQuestions?.length === 0 ? [''] : [...props.customQuestions]);
  const [addQuestionClicked, setAddQuestionClicked] = React.useState(false);

  useEffect(() => {
    if (error) {
      toast.error(error.message);
    } else if (!stages) {
      dispatch(listStages());
    }

    if (stages) {
      const defaultStages = stages.data
        .filter(stage => DATA.defaultStages.includes(stage.code))
        .map(stage => stage.id);
      props.setSelectedStages(defaultStages);
    }
  }, [error, stages]);

  const addCustomQuestion = () => {
    setAddQuestionClicked(true);
    if (!customQuestions.some(q => !q.trim())) {
      props.handleCustomQuestions(customQuestions.length, '');
      setAddQuestionClicked(false); // Reset after adding
    }
  };

  const isCustomQuestionSelected = props.selectedStages.some(stageId =>
    stages?.data.find(stage => stage.id === stageId)?.code === 'CUSTOM_QUESTIONS'
  );

  const customQuestionStage = stages?.data.find(stage => stage.code === 'CUSTOM_QUESTIONS');

  React.useEffect(() => {
    if (customQuestionStage && props.selectedStages.includes(customQuestionStage.id) && customQuestions.length === 0) {
      props.handleCustomQuestions(0, '');
    }
  }, [props.selectedStages, customQuestionStage]);

  const canAddQuestion = !customQuestions.some(q => !q.trim());

  return (
    <form
      className={cn('flex flex-col', props.className)}
      id="moduleForm"
      {...props}
    >
      <div className="flex flex-col font-semibold text-lg pb-4">
        Module
        <span className="text-[12px] text-!off-white font-normal">
          Select the module that you want to interview the candidate on.
        </span>
      </div>

      <>
        {loading ? (
          <Loader />
        ) : (
          <div className="flex flex-col md:w-[80%] w-full gap-2">
            <div className="items-top flex space-x-2">
              <Checkbox checked disabled id="INTRODUCTION" />
              <div className="grid gap-1.5 leading-none">
                <label
                  htmlFor="INTRODUCTION"
                  className="text-sm font-normal leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                >
                  Introduction
                </label>
              </div>
            </div>

            <div className="grid grid-cols-1 gap-2">
              {stages?.data.map((stage, index) => (
                <div key={index} className="items-top flex space-x-2">
                  <Checkbox
                    id={stage.id}
                    value={stage.id}
                    checked={props.selectedStages.includes(stage.id)}
                    onCheckedChange={() => {
                      props.handleStageSelect(stage.id, stage.id === customQuestionStage.id);
                    //   if (stage.code === 'CUSTOM_QUESTIONS' && !props.selectedStages.includes(stage.id)) {
                    //     setCustomQuestions(['']);
                    //   }
                    }}
                  />
                  <div className="grid gap-1.5 leading-none">
                    <label
                      htmlFor={stage.id}
                      className="text-sm font-normal leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                    >
                      {stage.name}
                    </label>
                  </div>
                </div>
              ))}
            </div>

            {customQuestionStage && props.selectedStages.includes(customQuestionStage.id) && (
              <div className="flex flex-col gap-2 mt-4">
                <div className="font-semibold text-sm">Custom Questions</div>
                {customQuestions.map((question, index) => (
                  <div key={index} className="flex space-x-2 items-center">
                    <Input
                      type="text"
                      placeholder="Enter your question"
                      value={question}
                      onChange={(e) => props.handleCustomQuestions(index, e.target.value, false)}
                      className="w-full"
                      label='question'
                      hidelabel
                    />
                    {customQuestions.length > 1 && (
                      <Button
                        type="button"
                        variant="outline"
                        size="icon"
                        onClick={() =>
                          props.handleCustomQuestions(index, null, true)
                          // setCustomQuestions(customQuestions.filter((_, i) => i !== index))
                        }
                        className="h-8 w-8"
                      >
                        <Trash2 className="h-4 w-4" />
                      </Button>
                    )}
                  </div>
                ))}
                <Button
                  type="button"
                  variant="outline"
                  size="sm"
                  onClick={addCustomQuestion}
                >
                  <Plus className="h-4 w-4 mr-2" />
                  Add Question
                </Button>
                {addQuestionClicked && !canAddQuestion && (
                  <p className="text-xs text-red-500 mt-1">
                    Please fill in all the questions before adding more.
                  </p>
                )}
              </div>
            )}

            <div className="flex flex-col pt-10 pb-2 text-[12px] text-!grey-200 font-light my-auto list-none">
              <div className="flex flex-row my-auto">
                <Info className="flex flex-col h-[0.8rem] mt-[0.4vh]" />
                <span className="flex flex-col">
                  If you have any other technical modules in mind, please write to us
                  at{' '}
                  <a
                    className="text-blue-600 hover:text-blue-400"
                    href="mailto:team@cognatoai.com"
                  >
                    team@cognatoai.com
                  </a>
                </span>
              </div>
            </div>
          </div>
        )}
      </>
    </form>
  );
}

function CandidateForm(props) {
  const { defaultCandidateValue, onResumeUpload, onSubmit } = props;

  const dispatch = useDispatch();

  const [resume, setResume] = useState();
  const [search, setSearch] = useState(null);
  const [show, setShow] = useState(false);
  //FIXME: To handle the case when candidate list was already fetched by any request; this showed the popover by default
  const [selectedCandidate, setSelectedCandidate] = useState();

  const { error, loading, candidates } = useSelector(state => state.listCandidates);

  useEffect(() => {
    if(error) {
      toast.error(error.message);
    } else if(candidates && search) {
      setShow(true);
    }
  }, [error, loading, candidates]);


  const onSearchHandler = (e) => {
    e.preventDefault();

    dispatch(listCandidates({
      search,
    }));
  }

  const handleFile = (e) => {
    let file = e.target.files[0];

    const fileType = file["type"];
    const validResumeTypes = ["application/pdf"];
    if(file.size / 1024 / 1024 > 4) {
      toast.error('Please upload file < 4MB');
      // e.target.files = new FileList();
      // TODO: remove file
    } else if (validResumeTypes.includes(fileType)) {
      setResume(file);
      onResumeUpload(file);
    } else {
      toast.error('Please upload a pdf.')
    }
  };

  return (
    <form className={cn(
      'flex flex-col',
      props.className
    )}
    id='candidateForm'
    onSubmit={onSubmit}
    >
      <div className='flex flex-row  font-semibold text-lg pb-4'>
        Candidate
      </div>

      <div className='flex flex-col gap-y-8'>
        <div className='flex flex-row md:w-[60%] w-full gap-2'>
          <div className='flex flex-col w-full'>
            <Popover open={show} onOpenChange={setShow}>
              <PopoverTrigger>
                <Input label='search' onChange={(e) => setSearch(e.target.value)} defaultValue={search} placeholder='Search previously added candidates by email' />
              </PopoverTrigger>
              <PopoverContent className='dark lg:w-[40vw] p-1'
                              onInteractOutside={(e) => {
                                setShow(false)
                              }}
              >
                <div className='flex flex-col w-full'>
                  {
                    candidates && candidates.count > 0 ? (candidates.results.map((candidate, index) => (
                      <div key={index} className='flex flex-row w-full px-4 py-2 rounded-md hover:bg-!grey-200/10 text-!grey-200 text-sm cursor-pointer'
                           onClick={(e) => {
                             e.preventDefault();
                             setSelectedCandidate(candidate);
                             setSearch(null);
                             setShow(false);
                           }}
                      >
                        {candidate.email}
                      </div>
                    ))) : (
                      <div className='flex flex-row w-full px-4 py-2 m-auto'>
                        <NoData />
                      </div>
                    )
                  }
                </div>
              </PopoverContent>
            </Popover>
          </div>
          <div className='flex flex-col justify-end'>
            <Button variant='default' loading={loading} onClick={onSearchHandler}>
              <Search className='m-auto h-[1.4rem]' />
            </Button>
          </div>
        </div>

        <Separator className='md:w-[60%] w-full' />

        <div className='flex flex-col md:w-[60%] w-full gap-4'>
          <div className='flex flex-row w-full text-sm'>
            Candidate Details
          </div>

          <div className='flex flex-row w-full gap-x-4'>
            <div className='flex flex-col w-full'>
              <Input label='first name' type='text' placeholder='Jon' defaultValue={selectedCandidate && selectedCandidate.first_name} required />
            </div>

            <div className='flex flex-col w-full'>
              <Input label='last name' type='text' placeholder='Doe' defaultValue={selectedCandidate && selectedCandidate.last_name} required />
            </div>
          </div>

          <div className='flex flex-row w-full gap-x-4'>
            <div className='flex flex-col w-full'>
              <Input label='email' type='email' placeholder='jon.doe@cognatoai.com' defaultValue={selectedCandidate && selectedCandidate.email} required />
            </div>

            <div className='flex flex-row w-full gap-x-2'>
              <div className='flex flex-col w-full'>
                <Input label='phone' type='phone' placeholder='+919876543210' defaultValue={selectedCandidate && selectedCandidate.phone} validate='[+]{1}[1-9]{1}[0-9]{0,2}[1-9]{1}[0-9]{6,14}' required />
              </div>
            </div>
          </div>

          <div className='flex flex-row w-full gap-x-4'>
            <div className='flex flex-col w-fit gap-y-1'>
              <Input className='display-none file:text-white file:bg-!grey-200/20 file:mr-2 file:rounded-md text-!grey-200 hover:file:bg-!grey-200/10 file:hover:cursor-pointer'
                     onChange={handleFile} label='resume' type='file' accept='application/pdf' placeholder={'WTF'} required={!(selectedCandidate && selectedCandidate.resume)} />
              {
                !resume && selectedCandidate && (
                  <>
                    <span className='flex flex-row text-sm text-!grey-200 gap-x-1'>
                      <span className='flex flex-col'>Current Resume: </span>
                      <a className='underline hover:text-!grey' target='_blank' href={selectedCandidate.resume}>{selectedCandidate.name}'s Resume</a>
                    </span>
                    <Input className='hidden' label='resume' type='text' value={selectedCandidate.resume} hidelabel={true} />
                  </>
                )
              }
            </div>
          </div>
        </div>
      </div>

      <div className='flex flex-col py-6 text-[12px] text-!grey-200 font-light my-auto list-none'>
        <div className='flex flex-row my-auto'>
          <Info className='flex flex-col h-[0.8rem] mt-[0.4vh]' />
          <span className='flex flex-col'>Please make sure that the candidate email is correct as he/she will receive invite to that email only.</span>
        </div>

        <div className='flex flex-row my-auto'>
          <Info className='flex flex-col h-[0.8rem] mt-[0.4vh]' />
          <span className='flex flex-col'>Interviews are manually approved based on resources, so, it might take some time. Please have patience.</span>
        </div>
      </div>
    </form>
  );
}

function ScheduleForm(props) {
  return (
    <form className={cn(
      'flex flex-col',
      props.className
    )}
    id='scheduleForm'
    {...props}
    >
      <div className='flex flex-col'>
        <div className='flex flex-row  font-semibold text-lg pb-4'>
          Schedule
        </div>

        <div className='flex flex-col md:w-[60%] w-full gap-4'>
          <div className='flex flex-row w-full gap-x-4'>
            <div className='flex flex-col w-full gap-y-1 text-!grey-200'>
              <div className='flex flex-row'>
                <Label className='flex flex-row text-[12px] gap-x-1'
                       htmlFor='start_date'>
                  <span className='flex flex-col'>Start Date</span>
                  {/*<span className='flex flex-col text-!red-600 my-auto'>*</span>*/}
                </Label>
              </div>
              <div className='flex flex-row'>
                <DatePicker label='start_date dark' />
              </div>
            </div>

            <div className='flex flex-col w-full gap-y-1 text-!grey-200'>
              <div className='flex flex-row'>
                <Label className='flex flex-row text-[12px] gap-x-1'
                       htmlFor='end_date'>
                  <span className='flex flex-col'>Expiration Date</span>
                  {/*<span className='flex flex-col text-!red-600 my-auto'>*</span>*/}
                </Label>
              </div>
              <div className='flex flex-row'>
                <DatePicker label='end_date dark' />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='flex flex-col pt-10'>
        <div className='flex flex-row  font-semibold text-lg pb-4'>
          Configurations
        </div>

        <div className='flex flex-col gap-2'>
          <div className="items-center flex space-x-2">
            <Switch checked={props.interviewConfig?.camera} id="camera"
              onCheckedChange={() => {
                props.setInterviewConfig({
                  ...props.interviewConfig,
                  camera: !props.interviewConfig?.camera
                })
              }}
            />
            <div className="grid gap-1.5 leading-none">
              <label
                htmlFor="camera"
                className="text-sm font-normal leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                Force camera ON
              </label>
            </div>
          </div>
          <div className="items-center flex space-x-2">
            <Switch checked={props.interviewConfig?.screen} id="screen"
                    onCheckedChange={() => {
                      props.setInterviewConfig({
                        ...props.interviewConfig,
                        screen: !props.interviewConfig?.screen
                      })
                    }}
            />
            <div className="grid gap-1.5 leading-none">
              <label
                htmlFor="screem"
                className="text-sm font-normal leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                Record Screen
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className='flex flex-col pt-10 pb-2 text-[12px] text-!grey-200 font-light my-auto list-none'>
        <div className='flex flex-row my-auto'>
          <Info className='flex flex-col h-[0.8rem] mt-[0.4vh]' />
          <span className='flex flex-col'>This is the time during which the interview will be active. Interview can be attempted ONLY ONCE anytime during these days.</span>
        </div>

        <div className='flex flex-row my-auto'>
          <Info className='flex flex-col h-[0.8rem] mt-[0.4vh]' />
          <span className='flex flex-col'>Since we are on beta, these are tentative schedule and the approval depends on the amount of resources available. The exact schedule will be mailed to you upon approval.</span>
        </div>
      </div>
    </form>
  );
}

function ScheduleInterviewStepper(props) {
  return (
    <div className='h-full w-full'>
      <ul className="relative flex flex-row gap-x-2">
        <li className="flex flex-col items-center gap-x-2 shrink basis-0 flex-1 group">
          <div className="flex flex-row min-w-7 min-h-7 inline-flex justify-center items-center text-xs align-middle">
            <img className="flex-shrink-0 size-7 rounded-full" src="https://images.unsplash.com/photo-1659482633369-9fe69af50bfb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8auto=format&fit=facearea&facepad=3&w=320&h=320&q=80" alt="Image Description" />
            <span className="ms-2 block text-sm font-medium text-gray-800 dark:text-white">
              Step
            </span>
          </div>
          <div className="w-full h-px flex-1 bg-gray-200 group-last:hidden dark:bg-neutral-700"></div>
        </li>

        <li className="flex items-center gap-x-2 shrink basis-0 flex-1 group">
          <div className="min-w-7 min-h-7 inline-flex justify-center items-center text-xs align-middle">
            <img className="flex-shrink-0 size-7 rounded-full" src="https://images.unsplash.com/photo-1659482633369-9fe69af50bfb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8auto=format&fit=facearea&facepad=3&w=320&h=320&q=80" alt="Image Description" />
            <span className="ms-2 block text-sm font-medium text-gray-800 dark:text-white">
              Step
            </span>
          </div>
          <div className="w-full h-px flex-1 bg-gray-200 group-last:hidden dark:bg-neutral-700"></div>
        </li>

        <li className="flex items-center gap-x-2 shrink basis-0 flex-1 group">
          <div className="min-w-7 min-h-7 inline-flex justify-center items-center text-xs align-middle">
            <img className="flex-shrink-0 size-7 rounded-full" src="https://images.unsplash.com/photo-1659482633369-9fe69af50bfb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8auto=format&fit=facearea&facepad=3&w=320&h=320&q=80" alt="Image Description" />
            <span className="ms-2 block text-sm font-medium text-gray-800 dark:text-white">
              Step
            </span>
          </div>
          <div className="w-full h-px flex-1 bg-gray-200 group-last:hidden dark:bg-neutral-700"></div>
        </li>
      </ul>
    </div>
  );
}

function ScheduleInterviewCardV1(props)  {
  const [currentStep, setCurrentStep] = useState(0);
  const [interviewData, setInterviewData] = useState({});

  const onJobSubmitHandler = (e) => {
    e.preventDefault();

    const data = UTILS.buildJsonFromForm(e.target);
    setInterviewData({
      ...interviewData,
      metadata: {
        job: {
          ...data,
        }
      }
    })

    setCurrentStep(1);
  }

  const onCandidateSubmitHandler = (e) => {
    e.preventDefault();

    const data = UTILS.buildJsonFromForm(e.target);

    /* TODO:
     * 1.Check if the hidden field is `create` or `read`
     * 2a. If create, then try to create the new candidate and set id in data
     * 2b. If read, then set the id from state
     */
    setInterviewData({
      ...interviewData,
      candidate: {
        ...data
      }
    });

    setCurrentStep(2);
  }

  const onScheduleSubmitHandler = (e) => {
    e.preventDefault();

    const data = UTILS.buildJsonFromForm(e.target);

    /* TODO:
     * 1.Check if the hidden field is `create` or `read`
     * 2a. If create, then try to create the new candidate and set id in data
     * 2b. If read, then set the id from state
     */
    setInterviewData({
      ...interviewData,
      ...data
    });

  }

  const stepForms = [
    {
      form: <JobForm id='jobForm' onSubmit={onJobSubmitHandler} />,
      actions: (
        <div className='flex flex-row gap-x-4'>
          <Button form='jobForm' variant='default' type='submit'>Next</Button>
        </div>
      ),
    },
    {
      form: <CandidateForm id='candidateForm' onSubmit={onCandidateSubmitHandler} />,
      actions: (
        <div className='flex flex-row gap-x-4'>
          <Button variant='secondary' onClick={() => setCurrentStep(0)}>&larr;&nbsp;Back</Button>
          <Button form='candidateForm' variant='default' type='submit'>Next</Button>
        </div>
      ),
    },
    {
      form: <ScheduleForm id='scheduleForm' onSubmit={onScheduleSubmitHandler} />,
      actions: (
        <div className='flex flex-row gap-x-4'>
          <Button form='scheduleForm' variant='default' type='submit'>Submit</Button>
        </div>
      ),
    }
  ]

  const onForwardHandler = (e) => {
    e.preventDefault();
    if(currentStep === steps.length) return;
    setCurrentStep(currentStep + 1);
  }

  const onBackwardHandler = (e) => {
    e.preventDefault();
    if(currentStep === 0) return;
    setCurrentStep(currentStep - 1);
  }

  const onSubmitHandler = (e) => {
    e.preventDefault();
    const data = UTILS.buildJsonFromForm(e.target);
    // console.log('onSubmitHandler', data)
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Schedule Interview</CardTitle>
        <CardDescription>Follow along the steps to schedule an interview.</CardDescription>
      </CardHeader>

      <Separator className='mb-[4vh] w-[98%] mx-auto' decorative />

      <CardContent>
        <form id='interviewForm' className='flex flex-col' onSubmit={onSubmitHandler}>
          <JobForm className={currentStep !== 0 && 'hidden'} />
          <CandidateForm className={currentStep !== 1 && 'hidden'} />
          <ScheduleForm className={currentStep !== 2 && 'hidden'} />
        </form>

      </CardContent>
      <CardFooter>
        <div className='flex flex-row gap-x-4'>
          {
            currentStep > 0 && (
              <Button variant='secondary' onClick={onBackwardHandler}>
                &larr;&nbsp;Back
              </Button>
            )
          }

          {
            (currentStep < stepForms.length - 1) ? (
              <Button variant='default' onClick={onForwardHandler}>
                Next
              </Button>
            ) : (
              <Button type='submit' form='interviewForm' variant='default'>
                Submit
              </Button>
            )
          }
        </div>
      </CardFooter>
    </Card>
  )
}

function InterviewGuidelineCard(props) {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Interview Guidelines</CardTitle>
        <CardDescription>Following are some information and guidelines to follow during the interview.</CardDescription>
      </CardHeader>

      <Separator className='mb-[4vh] w-[98%] mx-auto' decorative />

      <CardContent>
        <div className='flex flex-col'>
          <div className='flex flex-row'>

          </div>
        </div>

      </CardContent>
      <CardFooter>
        <div className='flex flex-row gap-x-4'>

        </div>
      </CardFooter>
    </Card>
  )
}

function ScheduleInterviewCard(props)  {
  const dispatch = useDispatch();

  const [currentStep, setCurrentStep] = useState(0);
  const [interviewData, setInterviewData] = useState({});
  const [resume, setResume] = useState();
  const [selectedStages, setSelectedStages] = useState([]);
  const [customQuestions, setCustomQuestions] = useState([]);
  const [customQuestionsStage, setCustomQuestionsStage] = useState();
  const [interviewConfig, setInterviewConfig] = useState({
    'camera': true,
    'screen': true,
  })
  const [showModal, setShowModal] = useState(false);

  const { error, loading, interview } = useSelector(state => state.createInterview);

  useEffect(() => {
    if(error) {
      toast.error(error.message);
    } else if(interview) {
      setCurrentStep(0);
      toast.success("Successfully scheduled interview!");
      setShowModal(true);

      // TODO: Find a better way to do this.
      // document.getElementById('jobForm').reset();
      document.getElementById('moduleForm').reset();
      document.getElementById('candidateForm').reset();
      document.getElementById('scheduleForm').reset();
      // setSelectedStages([])
      dispatch({
        type: CANDIDATE_LIST_RESET,
      })
      dispatch({
        type: INTERVIEW_CREATE_RESET
      })

    }
  }, [error, loading, interview]);

  const handleStageSelect = (stageId, custom=false) => {
    if(custom) setCustomQuestionsStage(stageId)
    setSelectedStages((prevStages) => {
      return prevStages.includes(stageId)
        ? prevStages.filter((id) => id !== stageId)
        : [...prevStages, stageId]
    });
  };

  const handleCustomQuestionChange = (index, value, del=false) => {
    if(del && customQuestions?.length) {
      setCustomQuestions(customQuestions.filter((_, i) => i !== index))
    } else {
      let newCustomQuestions = [...customQuestions]
      newCustomQuestions[index] = value;
      setCustomQuestions(newCustomQuestions);
    }
  };

  const onSubmitHandler = (data) => {
    const formData = new FormData();
    formData.append('resume', resume);
    formData.append('data', JSON.stringify(data));

    // setSelectedStages([]);
    dispatch(createInterview(formData));
    // toast.success("Successfully created");
  }

  const onJobSubmitHandler = (e) => {
    e.preventDefault();

    const data = UTILS.buildJsonFromForm(e.target);
    setInterviewData({
      ...interviewData,
      metadata: {
        job: {
          ...data,
        }
      }
    })

    setCurrentStep(1);
  }

  const onModuleSubmitHandler = (e) => {
    e.preventDefault();

    const cleanedCustomQuestions = [...customQuestions].filter(q => q.trim().length > 0)
    // console.log('final data --> ', data)
    if(selectedStages.includes(customQuestionsStage)) {
      if(!cleanedCustomQuestions || cleanedCustomQuestions.length === 0) {
        toast.error('Add at least one question when using custom question module.')
        return;
      }
    }

    // console.log('submitting -> ', selectedStages)
    setInterviewData({
      ...interviewData,
      stages: selectedStages,
      custom_questions: {
        stage: customQuestionsStage,
        questions: cleanedCustomQuestions
      }
    })

    setCurrentStep(2);
  }

  const onCandidateSubmitHandler = (e) => {
    e.preventDefault();

    let data = UTILS.buildJsonFromForm(e.target);
    delete data['resume'];
    /* TODO:
     * 1.Check if the hidden field is `create` or `read`
     * 2a. If create, then try to create the new candidate and set id in data
     * 2b. If read, then set the id from state
     */
    setInterviewData({
      ...interviewData,
      candidate: {
        ...data
      }
    });

    setCurrentStep(3);
  }

  const onScheduleSubmitHandler = (e) => {
    e.preventDefault();

    const data = UTILS.buildJsonFromForm(e.target);
    const config = {
      config: {
        ...interviewConfig
      }
    }

    /* TODO:
     * 1.Check if the hidden field is `create` or `read`
     * 2a. If create, then try to create the new candidate and set id in data
     * 2b. If read, then set the id from state
     */
    setInterviewData({
      ...interviewData,
      ...data,
      ...config
    });
    const x = {
      ...interviewData,
      ...data,
      ...config
    }
    console.log(x)
    // console.log(JSON.stringify(interviewData))
    onSubmitHandler(x);
  }

  return (
    <>
      {
        showModal && (
          <Modal message='Successfully requested interview. Please keep an eye on candidate inbox!'
             variant='success'
             onConfirmHandler={() => setShowModal(false)}
             onCloseHandler={() => {
               setShowModal(false);
               dispatch({
                 type: INTERVIEW_CREATE_RESET,
               });
             }}
          />
        )
      }
      <Card>
        <CardHeader>
          <CardTitle>Schedule Interview</CardTitle>
          <CardDescription>Follow along the steps to schedule an interview.</CardDescription>
        </CardHeader>

        <Separator className='mb-[4vh] w-[98%] mx-auto' decorative />

        <CardContent>
          <div className='flex flex-col'>
            <JobForm className={currentStep !== 0 ? 'hidden' : ''} onSubmit={onJobSubmitHandler} />
            <ModuleForm className={currentStep !== 1 ? 'hidden' : ''} selectedStages={selectedStages} onSubmit={onModuleSubmitHandler} handleStageSelect={handleStageSelect} handleCustomQuestions={handleCustomQuestionChange} customQuestions={customQuestions.length === 0 ? [''] : customQuestions} setSelectedStages={setSelectedStages} />
            <CandidateForm className={currentStep !== 2 ? 'hidden' : ''} onSubmit={onCandidateSubmitHandler} onResumeUpload={(file) => setResume(file)} />
            <ScheduleForm className={currentStep !== 3 ? 'hidden' : ''} onSubmit={onScheduleSubmitHandler} interviewConfig={interviewConfig} setInterviewConfig={setInterviewConfig} />
          </div>
        </CardContent>

        <CardFooter>
          <div className='flex flex-row gap-x-4'>
            {
              currentStep > 0 && (
                <Button variant='secondary' onClick={() => setCurrentStep(currentStep - 1)}>
                  &larr;&nbsp;Back
                </Button>
              )
            }

            {
              (currentStep < steps.length - 1) ? (
                <Button type='submit' form={steps[currentStep].formId} variant='default' disabled={props.disabled || (currentStep === 1 && selectedStages.length === 0)}>
                  Next
                </Button>
              ) : (
                <Button type='submit' form={steps[steps.length-1].formId} variant='default' loading={loading} disabled={props.disabled}>
                  Submit
                </Button>
              )
            }
          </div>
        </CardFooter>
      </Card>
    </>
  )
}


function HomeScreen(props) {
  // const { user } = useSelector(state => state.retrieveUser);
  const dispatch = useDispatch();

  const { error, organization, loading } = useSelector(state => state.retrieveOrganization)

  useEffect(() => {
    if(error) return;
    else if(!loading && !organization) {
      dispatch(retrieveOrganization(UTILS.getOrganization()));
    }
  }, [organization]);

  return (
    <div className='h-full w-full gap-y-4'>
      {
        organization && parseFloat(organization.credits) === 0 && (
          <Alert className='mb-4'
                 variant='danger'
                 title='Low credits'
                 description='You do not have sufficient credits to schedule any interviews.'
          />
        )
      }
      <Alert className='mb-4'
             variant='default'
             title='Beta Version'
             description="Please note that the interviews will be manually scheduled based on available resources. This might take some time. Upon successfull scheduling the candidate will receive an invitation on the candidate's email. "
      />
      <ScheduleInterviewCard disabled={organization && parseFloat(organization.credits) === 0} />
    </div>
  );
}

export default HomeScreen;
