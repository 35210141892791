import React from 'react';
import {Card, CardContent} from "src/components/ui/card";
import {Pickaxe} from "lucide-react";

function MemberCard(props) {
  return (
    <Card>
      <CardContent>
        Coming Soon
      </CardContent>
    </Card>
  )
}

function MemberScreen(props) {
  return (
    <div className='flex flex-col h-[79vh] w-full m-auto'>
      <div className='flex flex-col m-auto text-white gap-y-8'>
        <div className='flex flex-row'>
          <Pickaxe className='h-20 w-20 mx-auto' />
        </div>
        <div className='flex flex-row mx-auto text-4xl'>
          Coming Soon
        </div>
      </div>
    </div>
  );
}

export default MemberScreen;