export const AUDIO_STATUS = {
  INACTIVE: 'INACTIVE',
  READY: 'READY',
  PLAYING: 'PLAYING',
  PAUSED: 'PAUSED',
}

export const RECORDING_STATUS = {
  PENDING: 'PENDING',
  PROCESSING: 'PROCESSING',
  PROCESSED: 'PROCESSED',
  FAILED: 'FAILED',
}