import React from 'react';
import {cn} from "src/lib/utils";
import NoData from "src/components/NoData";
import {LoaderCircle} from "lucide-react";


function Table(props) {
  const { data, columns } = props;

  return (
    <div className={cn('w-full h-full border-[1px] border-!grey-200/30 rounded-lg overflow-x-auto', props.className)}>
      <table className='hidden md:table h-full w-full text-[0.9rem] border-collapse min-w-[600px]'>
        {/* Table layout for larger screens */}
        <thead>
        <tr>
          {columns.map(column => (
            <th key={column.id} className='p-4 text-left text-!grey-200 font-semibold text-[0.9rem]'>
              {column.header}
            </th>
          ))}
        </tr>
        </thead>
        <tbody>
        {data && data.length ? (
          data.map((row) => (
            <tr key={row.id} className='border-t-[1px] border-t-!grey-200/30 hover:bg-!grey-200/10'>
              {columns.map(column => (
                <td key={column.id} className='p-4'>
                  {column.cell({ row })}
                </td>
              ))}
            </tr>
          ))
        ) : props.loading ? (
          <tr>
            <td className='py-12' colSpan={columns.length}>
              <LoaderCircle className='m-auto h-12 w-12 animate-spin' />
            </td>
          </tr>
        ) : (
          <tr>
            <td className='py-12' colSpan={columns.length}>
              <NoData />
            </td>
          </tr>
        )}
        </tbody>
      </table>

      {/* Mobile stacked layout */}
      <div className='md:hidden'>
        {data && data.length ? (
          data.map((row) => (
            <div key={row.id} className='border-b-[1px] border-b-!grey-200/30 p-4 hover:bg-!grey-200/10'>
              {columns.map(column => (
                <div key={column.id} className='flex justify-between py-2'>
                  <span className='font-semibold text-!grey-200'>{column.header}:</span>
                  <span>{column.cell({ row })}</span>
                </div>
              ))}
            </div>
          ))
        ) : props.loading ? (
          <div className='py-12'>
            <LoaderCircle className='m-auto h-12 w-12 animate-spin' />
          </div>
        ) : (
          <div className='py-12'>
            <NoData />
          </div>
        )}
      </div>
    </div>
  );
}

export default Table;