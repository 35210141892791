import React from 'react';
import { CONFIG } from 'src/commons/config';
import toast from "react-hot-toast";
import {
  Code, FileQuestion,
  MessageCircleReply, Presentation, User, ArrowDownToLine, LoaderCircle, AudioLines, AArrowUp
} from 'lucide-react'
import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from "src/components/ui/accordion";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "src/components/ui/table";
import { Badge } from "src/components/ui/badge";
import {UTILS} from "src/commons/utils";
import {Card, CardContent, CardHeader, CardTitle} from "src/components/ui/card";
import {Tabs, TabsContent, TabsList, TabsTrigger} from "src/components/ui/tabs";
import {Label} from "src/components/ui/label";
import {DATA} from "src/data/data";
import {cn} from "src/lib/utils";
import Button from "src/components/Button";
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { retrieveInterview } from 'src/store/interview/interviewActions';
import { retrieveFeedback } from 'src/store/feedback/feedbackActions';
import { dowloadInterviewResult } from 'src/store/interview/interviewActions';
import Loader from 'src/components/Loader';
import { useAudioPlayer } from 'src/commons/hooks';
import { AUDIO_STATUS } from 'src/constants/enums';


const levelToStyle = {
  'Entry': 'bg-gray-400 text-gray-800 hover:bg-gray-300',
  'Junior': 'bg-green-400 text-green-800 hover:bg-green-300',
  'Mid': 'bg-yellow-400 text-yellow-800 hover:bg-yellow-300',
  'Senior': 'bg-blue-400 text-blue-800 hover:bg-blue-300',
  'Expert': 'bg-indigo-400 text-indigo-800 hover:bg-indigo-300',
}

export function InterviewDetails({ interview }) {
  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString();
  };

  const interviewData = [
    { label: "Interview ID", value: interview.id },
    { label: "Status", value: interview.status.toUpperCase() },
    ...(interview.started_datetime
      ? [
        {
          label: "Started Date & Time",
          value: formatDate(interview.started_datetime),
        },
      ]
      : []),
    ...(interview.ended_datetime
      ? [
        {
          label: "Ended Date & Time",
          value: formatDate(interview.ended_datetime),
        },
      ]
      : []),
    { label: "Interviewer", value: interview.interviewer.name },
    { label: "Job Role", value: interview.metadata.job.role },
  ];

  return (
    <div className='flex flex-col border-0 w-full text-!off-white gap-4'>
      <div className='flex flex-row font-bold text-2xl gap-x-2'>
        <span className='flex flex-col my-auto'><Presentation /></span>
        <span className='flex flex-col my-auto'>Interview Details</span>
      </div>
      <div className='flex flex-col w-full'>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {interviewData.map((item) => (
            <div key={item.label} className="p-4 bg-!off-white/5 rounded-md">
              <p className="font-medium text-white">{item.label}</p>
              <p className="text-!off-white/70">{item.value}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

function CandidateDetails({ candidate }) {
  const candidateData = [
    { label: "Name", value: candidate.name },
    { label: "Phone", value: candidate.phone },
    { label: "Email", value: candidate.email },
    { label: "Resume", value: (
        <a className='hover:underline' href={candidate.resume} target="_blank" rel="noopener noreferrer">
          View Resume
        </a>
    ) },
  ];

  return (
    <div className='flex flex-col border-0 w-full text-!off-white gap-4'>
      <div className='flex flex-row font-bold text-2xl gap-x-2'>
        <span className='flex flex-col my-auto'><User /></span>
        <span className='flex flex-col my-auto'>Candidate Details</span>
      </div>
      <div className='flex flex-col w-full'>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {candidateData.map((item) => (
            <div key={item.label} className="p-4 bg-!off-white/5 rounded-md">
              <p className="font-medium text-white">{item.label}</p>
              <p className="text-!off-white/70">{item.value}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export function FeedbackAccordionItem({ feedback }) {
  const categoryToTitle = {
    knowledge: "Knowledge",
    problem_solving: "Problem Solving",
    alignment_with_job: "Alignment with Job",
    communication_skills: "Communication Skills",
    overall: "Overall",
  };

  const title = UTILS.toTitleCase(feedback.category) || feedback.category;

  return (
    <Accordion type="single" collapsible className="w-full text-!off-white">
      <AccordionItem className='w-full ' value={feedback.category}>
        <AccordionTrigger className="hover:no-underline hover:opacity-80">
          <span className="font-normal w-[40%] text-start">{title}</span>
          <Badge
            variant="outline"
            className={
              cn(
                '',
                feedback.score >= "8.00"
                  ? "border-green-600 text-green-600"
                  : feedback.score >= "6.00"
                    ? "border-yellow-500 text-yellow-500"
                    : "border-red-600 text-red-600"
              )
            }
          >
            {feedback.score}
          </Badge>
        </AccordionTrigger>
        <AccordionContent>
          <p>{feedback.comment}</p>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
}

function FeedbackCard(props) {
  const {feedback} = props

  return (
    <div className='flex flex-col w-full gap-y-4 text-!off-white'>
      <div className='flex flex-row font-bold text-2xl gap-x-2'>
        <span className='flex flex-col my-auto'><MessageCircleReply /></span>
        <span className='flex flex-col my-auto'>Feedback</span>
      </div>
      <div className='flex flex-col w-full'>
        {
          feedback.map(f => (
            <FeedbackAccordionItem feedback={f} />
          ))
        }
      </div>
    </div>
  )
}

function AudioPlayer(props) {
  const { src, playbackRate = 1.0 } = props;
  const { audioStatus, play, pause, stop } = useAudioPlayer(src, playbackRate);

  return (
    <span
      className={cn('flex bg-!off-white/10 p-2 hover:cursor-pointer hover:bg-!off-white/20 rounded-full w-fit', audioStatus === AUDIO_STATUS.INACTIVE ? 'text-!off-white/60' : (audioStatus === AUDIO_STATUS.PAUSED ? 'text-orange-500' : 'text-green-500'))}
      onClick={() => {
        if (audioStatus === AUDIO_STATUS.INACTIVE || audioStatus === AUDIO_STATUS.PAUSED) play(src);
        else pause();
      }}
    >
      <AudioLines />
    </span>
  )
}

function DiscussionCard(props) {
  const { title, feedback, discussion } = props

  return (
    <div className='flex flex-col w-full gap-y-4 text-!off-white'>
      <div className='flex flex-col gap-4'>
        <div className='flex flex-row font-bold text-2xl gap-x-2'>
          <span className='flex flex-col my-auto'><FileQuestion /></span>
          <span className='flex flex-col my-auto'>{ UTILS.toTitleCase(title) }</span>
        </div>

        {
          feedback && (
            <div className='flex flex-col gap-y-4 bg-!off-white/5 p-4 rounded-md'>
              <div className='flex flex-wrap gap-2'>
                <Badge>Score: {feedback.score}/10</Badge>
                <Badge className={levelToStyle[feedback.level]}>{feedback.level}</Badge>
              </div>
              <p className='font-light text-[13px]'>{feedback.detail}</p>
            </div>
          )
        }
      </div>
      <div className='flex flex-col w-full'>
        {
          discussion.map((q, idx) => (
            <Accordion key={idx} type="single" collapsible className="w-full text-!off-white">
              <AccordionItem className='w-full' value={'Question ' + (idx+1)}>
                <AccordionTrigger className="flex hover:no-underline hover:opacity-80">
                  <span className="font-normal">{'Question ' + (idx+1)}</span>
                </AccordionTrigger>
                <AccordionContent>
                  <div className='flex flex-col gap-y-4'>
                    <p className='font-semibold'>{q.question}</p>
                    <p className='bg-!off-white/5 p-4 rounded-md'>{q.answer}</p>
                    {
                      q.audio && (
                        <AudioPlayer key={idx} src={q.audio} />
                      )
                    }
                  </div>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          ))
        }
      </div>
    </div>
  )
}

function ProblemSolvingFeedback({ comment }) {
  // You can add more details related to problem solving here if needed
  return <p>{comment}</p>;
}

export function CodingQuestionTab(props) {
  const { discussion, feedback } = props;

  const validCodingQuestions = discussion.filter(
    (question) => question.question !== null
  );

  if (validCodingQuestions.length === 0) {
    return (
      <Card>
        <CardHeader>
          <CardTitle>No Coding Questions</CardTitle>
        </CardHeader>
        <CardContent>
          <p>There were no coding questions for this interview.</p>
        </CardContent>
      </Card>
    );
  }

  return (
    <div className='flex flex-col gap-y-4 text-white/80'>
      <div className='flex flex-col gap-4'>
        <div className='flex flex-row font-bold text-2xl gap-x-2'>
          <span className='flex flex-col my-auto'><Code /></span>
          <span className='flex flex-col my-auto'>Coding</span>
        </div>

        {
          feedback && (
            <div className='flex flex-col gap-y-4 bg-!off-white/5 p-4 rounded-md'>
              <div className='flex flex-wrap gap-2'>
                <Badge>Score: {feedback.score}/10</Badge>
                <Badge className={levelToStyle[feedback.level]}>{feedback.level}</Badge>
              </div>
              <p className='font-light text-[13px]'>{feedback.detail}</p>
            </div>
          )
        }
      </div>
      <div className='flex flex-col '>
        <Tabs defaultValue={validCodingQuestions[0].question} className="w-full">
          <TabsList>
            {validCodingQuestions.map((question, index) => (
              <TabsTrigger className='text-white/80' key={index} value={question.question}>
                Question {index + 1}
              </TabsTrigger>
            ))}
          </TabsList>
          {validCodingQuestions.map((question, index) => (
            <TabsContent key={index} value={question.question}>
              <Card>
                <CardHeader>
                  <CardTitle>Question {index + 1}</CardTitle>
                </CardHeader>
                <CardContent className="space-y-4">
                  <p className="whitespace-pre-line">{question.question}</p>
                  {question.code && (
                    <div>
                      <Label className="mb-1">Code:</Label>
                      <pre className="p-4 rounded-md overflow-x-auto bg-!off-white/5">
                    <code className="text-sm font-mono">
                      {question.code}
                    </code>
                  </pre>
                    </div>
                  )}
                  <div className="flex items-center space-x-4">
                    <Badge variant="outline">Hints: {question.hints}</Badge>
                    <Badge variant="outline">Tries: {question.retries}</Badge>
                    {question.correct !== null && (
                      <Badge
                        variant={question.correct ? "outline" : "destructive"}
                        className={question.correct ? "border-green-600 text-green-600 bg-green-600/10" : ''}
                      >
                        {question.correct ? "Correct" : "Incorrect"}
                      </Badge>
                    )}
                  </div>
                </CardContent>
              </Card>
            </TabsContent>
          ))}
        </Tabs>
      </div>
    </div>
  );
}

function InterviewResultScreen(props) {
  const result = DATA.result
  // const interview = DATA.interview

  const { id } = useParams();
  const { error, loading, interview } = useSelector(state => state.retrieveInterview)
  const { error: feedbackError, loading: feedbackLoading, feedback } = useSelector(state => state.retrieveFeedback)
  const { error: downloadError, loading: downloadLoading, success } = useSelector(state => state.downloadInterviewResult)

  const dispatch = useDispatch();

  useEffect(() => {
    if(error) {
      toast.error(error.message)
    } else if(!interview || id !== interview.id) {
      dispatch(retrieveInterview(id))
    }

    if(feedbackError) {
      toast.error(error.message)
    } else if(!feedback || id !== interview.id) {
      dispatch(retrieveFeedback(id))
    }
  }, [])

  return (
    <div className='flex flex-col w-full px-10 gap-16'>
      {/*<div className='flex flex-row justify-end w-full text-white'>*/}
      {/*  /!* <span className='flex-col font-bold text-xl'>Interview Result</span> *!/*/}
      {/*  <span*/}
      {/*    className='flex-col rounded-full p-3 bg-white/20 text-white hover:bg-white/30 hover:cursor-pointer'*/}
      {/*    onClick={() => dispatch(dowloadInterviewResult(id))}*/}
      {/*  >*/}
      {/*    {*/}
      {/*      downloadLoading ? (*/}
      {/*        <LoaderCircle className='animate-spin' />*/}
      {/*      ) : (*/}
      {/*        <ArrowDownToLine />*/}
      {/*      )*/}
      {/*    }*/}
      {/*  </span>*/}
      {/*</div>*/}

      {
        loading ? (
          <Loader />
        ) : interview ? (
          <>
            <div className='flex  w-full'>
              <InterviewDetails interview={interview}/>
            </div>

            <div className='flex  w-full'>
              <CandidateDetails candidate={interview?.candidate}/>
            </div>
          </>
        ) : (
          <div></div>
        )
      }

      {
        feedbackLoading ? (
          <Loader />
        ) : feedback ? (
        <>
          {/*<div className='flex  w-full'>*/}
          {/*  <FeedbackCard feedback={feedback.feedback}/>*/}
          {/*</div>*/}

          {
            Object.entries(feedback)
              .filter(([key]) => !['feedback', 'coding'].includes(key))
              .map(([key, value], idx) => (
                <div key={key} className="flex w-full">
                  <DiscussionCard title={key} discussion={value.discussion} feedback={value.feedback} />
                </div>
              ))
          }

          {
            feedback.coding?.discussion?.length > 0 && (
              <div className='flex flex-col w-full'>
                <CodingQuestionTab discussion={feedback.coding?.discussion} feedback={feedback.coding?.feedback} />
              </div>
            )
          }
        </>
        ) : (
          <div></div>
        )
      }
    </div>
  );
}

export default InterviewResultScreen;
