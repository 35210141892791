import React, {useEffect} from 'react';
import {Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle} from "src/components/ui/card";
import {Separator} from "src/components/ui/separator";
import {DATA} from "src/data/data";
import {Badge} from "src/components/ui/badge";
import {UTILS} from "src/commons/utils";
import {format} from "date-fns";
import {Plus, Search} from "lucide-react";
import Button from "src/components/Button";

import Table from "src/components/Table";
import {useDispatch, useSelector} from "react-redux";
import toast from "react-hot-toast";
import {retrieveOrganization} from "src/store/organization/organizationActions";
import Loader from "src/components/Loader";
import {retrieveActiveSubscription} from "src/store/subscription/subscriptionActions";
import {CONFIG} from "src/commons/config";
import {listOrders} from "src/store/order/orderActions";


function SubscriptionOverview(props) {
  const {billing} = props
  const {subscription} = billing;

  return (
    <div className='flex md:flex-row flex-col w-full gap-4'>
      <div className='flex flex-col md:w-[30%] w-full'>
        <Card className='h-full w-full'>
          <CardHeader>
            <div className='flex flex-row justify-between'>
              <CardTitle className='text-[1.2rem] my-auto'>Credits</CardTitle>
              <Button className='py-2 px-1 h-fit w-fit hover:cursor-not-allowed' variant='default' disabled>
                <Plus className='m-auto h-[1rem]' />
              </Button>
            </div>
          </CardHeader>

          <CardContent>
            <div className='flex flex-col gap-y-4'>
              <div className='flex flex-col gap-y-1'>

                <span className='flex flex-row text-4xl font-semibold text-white items-baseline'>
                  {Math.floor(parseFloat(billing.credits) / CONFIG.INTERVIEW_COST)}&nbsp;
                  <span className='text-sm text-!grey-200 font-normal align-baseline'>interviews</span>
                </span>
              </div>

            </div>
          </CardContent>
        </Card>
      </div>

      <div className='flex flex-col w-full'>
        <Card className='h-full w-full justify-between'>
          <CardHeader>
            <div className='flex flex-row justify-between'>
              <CardTitle className='text-[1.2rem]'>{subscription.plan}</CardTitle>
              <Badge className='my-auto'>{UTILS.toTitleCase(subscription.status)}</Badge>
            </div>
          </CardHeader>

          <CardContent>
            <div className='flex flex-col gap-y-4'>
              {/*<div className='flex flex-col gap-y-1'>*/}
              {/*  <span className='flex flex-row text-[0.85rem] text-!grey-200'>*/}
              {/*    Credits Remaining:*/}
              {/*  </span>*/}

              {/*    <span className='flex flex-row text-xl text-white'>*/}
              {/*    {billing.credits_remaining / 10}*/}
              {/*  </span>*/}
              {/*</div>*/}

              <div className='flex flex-col text-[0.85rem]'>
                <div className='flex flex-row'>
                  <span className='text-!grey-200'>Started:&nbsp;</span>
                  <span className=''>{subscription.start ? format(new Date(subscription.start), 'dd LLL yyyy HH:MM:SS') : 'N.A'}</span>
                </div>
                <div className='flex flex-row'>
                  <span className='text-!grey-200'>Expiration:&nbsp;</span>
                  <span className=''>{subscription.end ? format(new Date(subscription.start), 'dd LLL yyyy HH:MM:SS') : 'N.A'}</span>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  )
}


const creditTableColumns = [
  {
    id: 'id',
    header: 'Id',
    cell: ({row}) => {
      return <div>{row.id.split('-')[0]}</div>
    },
    enableSorting: false,
    enableHiding: false,
  },
  {
    id: 'created',
    header: 'Created',
    cell: ({row}) => {
      return <div>{format(new Date(row.created), 'dd LLL yyyy')}</div>
    },

  },
  {
    id: 'type',
    header: 'Type',
    cell: ({row}) => {
      return <Badge>{UTILS.toTitleCase(row.type)}</Badge>
    },

  },
  {
    id: 'transaction_type',
    header: 'Transaction',
    cell: ({row}) => {
      return <Badge>{UTILS.toTitleCase(row.transaction_type)}</Badge>
    },
  },
  {
    id: 'amount',
    header: 'Amount',
    cell: ({row}) => {
      return <div>{(row.amount)/CONFIG.INTERVIEW_COST}</div>
    },
    enableSorting: false,
    enableHiding: false,
  },
]

function CreditHistoryCard(props) {
  // const {orders} = DATA;
  const dispatch = useDispatch();

  const { error, loading, orders } = useSelector(state => state.listOrders);

  useEffect(() => {
    if(error) {
      toast.error(error.message);
    } else if(!error && !loading && !orders) {
      dispatch(listOrders({}));
    }
  }, [error, loading, orders]);


  return (
    <Card className='border-0 p-0 w-full'>
      <CardHeader className='px-0'>
        <CardTitle className='text-[1.2rem]'>Credit History</CardTitle>
        <CardDescription>Last few credit transactions.</CardDescription>
      </CardHeader>

      <CardContent className='px-0'>
        <Table data={orders && orders.results} columns={creditTableColumns} loading={loading}  />
      </CardContent>
    </Card>
  );
}

function BillingCard(props) {
  const {billing} = props;

  return (
    <Card className='border-0'>
      <CardHeader>
        <CardTitle>Billing</CardTitle>
        <CardDescription>Billing related details. More details to be added soon.</CardDescription>
      </CardHeader>

      <Separator className='mb-[4vh] w-[98%] mx-auto' decorative />

      <CardContent>
        <div className='flex flex-col w-full gap-y-4'>
          <div className='flex flex-row w-full'>
            <SubscriptionOverview billing={billing} />
          </div>

          <div className='flex flex-row w-full'>
            <CreditHistoryCard />
          </div>
        </div>

      </CardContent>

      <CardFooter>

      </CardFooter>
    </Card>
  )
}

function BillingScreen(props) {
  const dispatch = useDispatch();

  const { error: orgError, loading: orgLoading, organization } = useSelector(state => state.retrieveOrganization);
  const { error: subscriptionError, loading: subscriptionLoading, subscription } = useSelector(state => state.retrieveActiveSubscription);

  useEffect(() => {
    if(orgError) {
      toast.error(orgError.message);
    } else if(!orgError && !orgLoading && !organization) {
      dispatch(retrieveOrganization(
        UTILS.getOrganization()
      ))
    }
  }, [orgError, orgLoading, organization]);

  useEffect(() => {
    if(subscriptionError) {
      toast.error(subscriptionError.message);
    } else if(!subscriptionError && !subscriptionLoading && !subscription) {
      dispatch(retrieveActiveSubscription());
    }
  }, [subscriptionError, subscriptionLoading, subscription  ]);


  return (
    <div className='h-full w-full'>
      {
        (orgLoading || subscriptionLoading) ? (
          <Loader />
        ) : (organization && subscription) ? (
          <BillingCard billing={{
            subscription: subscription,
            ...organization
          }} />
        ) : (
          <></>
        )
      }
    </div>
  );
}

export default BillingScreen;