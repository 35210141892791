// memberConstants.js
export const MEMBER_CREATE_REQUEST = 'MEMBER_CREATE_REQUEST';
export const MEMBER_CREATE_SUCCESS = 'MEMBER_CREATE_SUCCESS';
export const MEMBER_CREATE_FAIL = 'MEMBER_CREATE_FAIL';
export const MEMBER_CREATE_RESET = 'MEMBER_CREATE_RESET';

export const MEMBER_UPDATE_REQUEST = 'MEMBER_UPDATE_REQUEST';
export const MEMBER_UPDATE_SUCCESS = 'MEMBER_UPDATE_SUCCESS';
export const MEMBER_UPDATE_FAIL = 'MEMBER_UPDATE_FAIL';
export const MEMBER_UPDATE_RESET = 'MEMBER_UPDATE_RESET';

export const MEMBER_RETRIEVE_REQUEST = 'MEMBER_RETRIEVE_REQUEST';
export const MEMBER_RETRIEVE_SUCCESS = 'MEMBER_RETRIEVE_SUCCESS';
export const MEMBER_RETRIEVE_FAIL = 'MEMBER_RETRIEVE_FAIL';
export const MEMBER_RETRIEVE_RESET = 'MEMBER_RETRIEVE_RESET';

export const MEMBER_LIST_REQUEST = 'MEMBER_LIST_REQUEST';
export const MEMBER_LIST_SUCCESS = 'MEMBER_LIST_SUCCESS';
export const MEMBER_LIST_FAIL = 'MEMBER_LIST_FAIL';
export const MEMBER_LIST_RESET = 'MEMBER_LIST_RESET';
