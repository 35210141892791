// interviewConstants.js
export const INTERVIEW_CREATE_REQUEST = 'INTERVIEW_CREATE_REQUEST';
export const INTERVIEW_CREATE_SUCCESS = 'INTERVIEW_CREATE_SUCCESS';
export const INTERVIEW_CREATE_FAIL = 'INTERVIEW_CREATE_FAIL';
export const INTERVIEW_CREATE_RESET = 'INTERVIEW_CREATE_RESET';

export const INTERVIEW_RETRIEVE_REQUEST = 'INTERVIEW_RETRIEVE_REQUEST';
export const INTERVIEW_RETRIEVE_SUCCESS = 'INTERVIEW_RETRIEVE_SUCCESS';
export const INTERVIEW_RETRIEVE_FAIL = 'INTERVIEW_RETRIEVE_FAIL';
export const INTERVIEW_RETRIEVE_RESET = 'INTERVIEW_RETRIEVE_RESET';

export const INTERVIEW_LIST_REQUEST = 'INTERVIEW_LIST_REQUEST';
export const INTERVIEW_LIST_SUCCESS = 'INTERVIEW_LIST_SUCCESS';
export const INTERVIEW_LIST_FAIL = 'INTERVIEW_LIST_FAIL';
export const INTERVIEW_LIST_RESET = 'INTERVIEW_LIST_RESET';

export const INTERVIEW_RESULT_DOWNLOAD_REQUEST = 'INTERVIEW_RESULT_DOWNLOAD_REQUEST';
export const INTERVIEW_RESULT_DOWNLOAD_SUCCESS = 'INTERVIEW_RESULT_DOWNLOAD_SUCCESS';
export const INTERVIEW_RESULT_DOWNLOAD_FAIL = 'INTERVIEW_RESULT_DOWNLOAD_FAIL';
export const INTERVIEW_RESULT_DOWNLOAD_RESET = 'INTERVIEW_RESULT_DOWNLOAD_RESET';
