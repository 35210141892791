import {applyMiddleware, combineReducers, createStore} from "redux";
import {composeWithDevTools} from "@redux-devtools/extension";
import {thunk} from "redux-thunk";

import {
  createInterview,
  retrieveInterview,
  listInterviews
} from 'src/store/interview/interviewReducers';
import {
  createMember,
  retrieveMember,
  listMembers
} from 'src/store/member/memberReducers';
import {
  createOrder,
  retrieveOrder,
  listOrders,
} from 'src/store/order/orderReducers';
import {
  createOrganization,
  retrieveOrganization,
  updateOrganization,
  listOrganizations
} from 'src/store/organization/organizationReducers';
import {
  createUser,
  retrieveUser,
  updateUser,
  changePassword
} from 'src/store/user/userReducers';
import {
  createCandidate,
  retrieveCandidate,
  listCandidates,
} from 'src/store/candidate/candidateReducers';
import {
  createSubscription,
  retrieveSubscription,
  retrieveActiveSubscription,
  listSubscriptions,
} from 'src/store/subscription/subscriptionReducers';
import {
  createReview,
  retrieveReview,
  listReviews
} from "src/store/review/reviewReducers";
import {
  retrieveFeedback
} from "src/store/feedback/feedbackReducers";
import {
  listStages
} from "src/store/stage/stageReducers";
import {
  retrieveActivity
} from "src/store/activity/activityReducers";
import {
  retrieveRecording
} from "src/store/recording/recordingReducers";
import {
  downloadInterviewResult
} from "src/store/interview/interviewReducers";


const reducers = combineReducers({
  createInterview,
  retrieveInterview,
  listInterviews,

  createMember,
  retrieveMember,
  listMembers,

  createOrder,
  retrieveOrder,
  listOrders,

  createOrganization,
  retrieveOrganization,
  updateOrganization,
  listOrganizations,

  createUser,
  retrieveUser,
  updateUser,
  changePassword,

  createCandidate,
  retrieveCandidate,
  listCandidates,

  createSubscription,
  retrieveSubscription,
  retrieveActiveSubscription,
  listSubscriptions,

  createReview,
  retrieveReview,
  listReviews,

  retrieveFeedback,

  listStages,

  retrieveActivity,
  retrieveRecording,

  downloadInterviewResult
})

const initialState = {

}

const middlewares = [thunk]

const store = createStore(reducers, initialState,
  composeWithDevTools(applyMiddleware(...middlewares)))

export default store;
