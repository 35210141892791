// interviewReducers.js
import {
  INTERVIEW_CREATE_REQUEST,
  INTERVIEW_CREATE_SUCCESS,
  INTERVIEW_CREATE_FAIL,
  INTERVIEW_CREATE_RESET,

  INTERVIEW_RETRIEVE_REQUEST,
  INTERVIEW_RETRIEVE_SUCCESS,
  INTERVIEW_RETRIEVE_FAIL,
  INTERVIEW_RETRIEVE_RESET,

  INTERVIEW_LIST_REQUEST,
  INTERVIEW_LIST_SUCCESS,
  INTERVIEW_LIST_FAIL,
  INTERVIEW_LIST_RESET,

  INTERVIEW_RESULT_DOWNLOAD_REQUEST,
  INTERVIEW_RESULT_DOWNLOAD_SUCCESS,
  INTERVIEW_RESULT_DOWNLOAD_FAIL,
  INTERVIEW_RESULT_DOWNLOAD_RESET,
} from './interviewConstants';

export const createInterview = (state = {}, action) => {
  switch (action.type) {
    case INTERVIEW_CREATE_REQUEST:
      return { loading: true };

    case INTERVIEW_CREATE_SUCCESS:
      return {
        loading: false,
        interview: action.payload
      };

    case INTERVIEW_CREATE_FAIL:
      return {
        loading: false,
        error: action.payload
      };

    case INTERVIEW_CREATE_RESET:
      return {};

    default:
      return state;
  }
};

export const retrieveInterview = (state = {}, action) => {
  switch (action.type) {
    case INTERVIEW_RETRIEVE_REQUEST:
      return { loading: true };

    case INTERVIEW_RETRIEVE_SUCCESS:
      return {
        loading: false,
        interview: action.payload
      };

    case INTERVIEW_RETRIEVE_FAIL:
      return {
        loading: false,
        error: action.payload
      };

    case INTERVIEW_RETRIEVE_RESET:
      return {};

    default:
      return state;
  }
};

export const listInterviews = (state = {}, action) => {
  switch (action.type) {
    case INTERVIEW_LIST_REQUEST:
      return { loading: true };

    case INTERVIEW_LIST_SUCCESS:
      return {
        loading: false,
        interviews: action.payload
      };

    case INTERVIEW_LIST_FAIL:
      return {
        loading: false,
        error: action.payload
      };

    case INTERVIEW_LIST_RESET:
      return {};

    default:
      return state;
  }
};


export const downloadInterviewResult = (state = {}, action) => {
  switch (action.type) {
    case INTERVIEW_RESULT_DOWNLOAD_REQUEST:
      return { loading: true };

    case INTERVIEW_RESULT_DOWNLOAD_SUCCESS:
      return {
        loading: false,
        success: true
      };

    case INTERVIEW_RESULT_DOWNLOAD_FAIL:
      return {
        loading: false,
        error: action.payload
      };

    case INTERVIEW_RESULT_DOWNLOAD_RESET:
      return {};

    default:
      return state;
  }
};
