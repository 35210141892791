// billingConstants.js
export const ORDER_CREATE_REQUEST = 'ORDER_CREATE_REQUEST';
export const ORDER_CREATE_SUCCESS = 'ORDER_CREATE_SUCCESS';
export const ORDER_CREATE_FAIL = 'ORDER_CREATE_FAIL';
export const ORDER_CREATE_RESET = 'ORDER_CREATE_RESET';

export const ORDER_UPDATE_REQUEST = 'ORDER_UPDATE_REQUEST';
export const ORDER_UPDATE_SUCCESS = 'ORDER_UPDATE_SUCCESS';
export const ORDER_UPDATE_FAIL = 'ORDER_UPDATE_FAIL';
export const ORDER_UPDATE_RESET = 'ORDER_UPDATE_RESET';

export const ORDER_RETRIEVE_REQUEST = 'ORDER_RETRIEVE_REQUEST';
export const ORDER_RETRIEVE_SUCCESS = 'ORDER_RETRIEVE_SUCCESS';
export const ORDER_RETRIEVE_FAIL = 'ORDER_RETRIEVE_FAIL';
export const ORDER_RETRIEVE_RESET = 'ORDER_RETRIEVE_RESET';

export const ORDER_LIST_REQUEST = 'ORDER_LIST_REQUEST';
export const ORDER_LIST_SUCCESS = 'ORDER_LIST_SUCCESS';
export const ORDER_LIST_FAIL = 'ORDER_LIST_FAIL';
export const ORDER_LIST_RESET = 'ORDER_LIST_RESET';
