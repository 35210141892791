import React, {useEffect} from 'react';
import Loading from "src/components/Loading";
import Modal from "src/components/Modal";
import {CONFIG} from "src/commons/config";
import {UTILS} from "src/commons/utils";
import {useDispatch} from "react-redux";

function ExpiredModal(props) {
  return (
    <div className='fixed left-0 top-0 h-screen w-screen bg-!black/60 z-50'>
      <Modal variant='danger' message='Session Expired. Please Log in Again.'
             onConfirmHandler={() => {
               UTILS.removeToken();
               window.location.replace(CONFIG.AUTH_FRONTEND_URL + '/login')
             }}
      />
    </div>
  );
}

export default ExpiredModal;
