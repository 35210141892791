import React, {useEffect} from 'react';
import Loading from "src/components/Loading";
import {CONFIG} from "src/commons/config";
import {UTILS} from "src/commons/utils";

function LogoutScreen(props) {
  useEffect(() => {
    UTILS.removeToken();
    window.location.replace(CONFIG.AUTH_FRONTEND_URL);
  }, []);


  return (
    <div className='fixed left-0 top-0 h-screen w-screen bg-!black/60 z-50'>
      <Loading />
    </div>
  );
}

export default LogoutScreen;