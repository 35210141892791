import {CONFIG} from "src/commons/config";
import {UTILS} from "src/commons/utils";


export const INTERVIEW_APIS = {
  create: (data, configs) => {
    return UTILS.request({
      baseURL: CONFIG.APP_BACKEND_URL,
      url: `/api/v2/interviews/`,
      method: 'POST',
      data: data,
      ...configs
    })
  },

  retrieve: (id, configs) => {
    return UTILS.request({
      baseURL: CONFIG.APP_BACKEND_URL,
      url: `/api/v2/interviews/${id}/`,
      method: 'GET',
      ...configs
    })
  },

  list: (params, configs) => {
    return UTILS.request({
      baseURL: CONFIG.APP_BACKEND_URL,
      url: `/api/v2/interviews/`,
      method: 'GET',
      params: params,
      ...configs
    })
  },

  downloadResult: (id, configs) => {
    return UTILS.request({
      baseURL: CONFIG.APP_BACKEND_URL,
      url: `/api/v2/interviews/${id}/result`,
      method: 'GET',
      ...configs
    })
  }
}
