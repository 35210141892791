import React from 'react';
import {Route, Routes} from "react-router-dom";

import NotFoundScreen from "src/screens/NotFoundScreen";
import HomeScreen from "src/screens/HomeScreen";
import InterviewScreen from "src/screens/InterviewScreen";
import OrganizationScreen from "src/screens/OrganizationScreen";
import BillingScreen from "src/screens/BillingScreen";
import SettingsScreen from "src/screens/SettingsScreen";
import CandidateScreen from "src/screens/CandidateScreen";
import MemberScreen from "src/screens/MemberScreen";
import LogoutScreen from "src/screens/LogoutScreen";
import ExpiredModal from "src/components/ExpiredModal";
import ResumeReviewScreen from "src/screens/ResumeReviewScreen";
import InterviewResultScreen from "src/screens/InterviewResultScreen";
import InterviewResultScreenV2 from "src/screens/InterviewResultScreenV2";

function Router(props) {
  return (
    <Routes>
      <Route path='' element={<HomeScreen />} />
      <Route path='/interviews' element={<InterviewScreen />} />
      <Route path='/candidates' element={<CandidateScreen />} />
      <Route path='/organizations' element={<OrganizationScreen />} />
      <Route path='/organizations/billing' element={<BillingScreen />} />
      <Route path='/organizations/members' element={<MemberScreen />} />
      <Route path='/resume/review' element={<ResumeReviewScreen />} />
      <Route path='/settings' element={<SettingsScreen />} />
      <Route path='/logout' element={<LogoutScreen />} />
      <Route path='/expired' element={<ExpiredModal />} />
      <Route path='/interviews/:id/result' element={<InterviewResultScreenV2 />} />
      <Route path="*" element={<NotFoundScreen />} />
    </Routes>
  );
}

export default Router;
