import React from 'react';
import {Card, CardHeader} from "src/components/ui/card";
import {X, CircleCheck, CircleAlert} from 'lucide-react';
import Button from 'src/components/Button';

function Modal(props) {
  return (
    <div id="popup-modal" tabIndex="-1"
         className={`fixed dark font-Inter top-0 left-0 right-0 z-50 p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-full bg-!black/80`}
    >
      <div className="relative flex flex-1 w-full max-w-md h-full m-auto px-4">
        <div className="relative rounded-lg shadow bg-!black border-[1px] border-!grey-200/30 m-auto"
        >
          <span className='absolute top-3 right-2.5 text-!grey-200 hover:bg-!grey-200/20 hover:cursor-pointer p-[2px] rounded-md'>
            <X className='h-[1.4rem] w-[1.4rem]' onClick={() => {
              if(props.onCloseHandler) props.onCloseHandler();
              else props.onConfirmHandler();
            }} />
          </span>

          <div className="p-6 text-center">
            {/*<svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true"*/}
            {/*     xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">*/}
            {/*  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"*/}
            {/*        d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>*/}
            {/*</svg>*/}
            {
              props.variant === 'success' ? (
                <CircleCheck className='h-[4rem] w-[4rem] m-auto text-!green-600 bg-!green-600/20 rounded-full' />
              ) : props.variant === 'danger' ? (
                <CircleAlert className='h-[4rem] w-[4rem] m-auto text-!red-600 bg-!red-600/20 rounded-full' />
              ) : (
                <CircleAlert className='h-[4rem] w-[4rem] m-auto text-!grey-200 bg-!grey-200/20 rounded-full' />
              )
            }

            <h3 className="my-5 text-lg font-semibold text-!grey-200">
              {props.message || 'Are you sure you want to delete this product?'}
            </h3>
            <Button data-modal-hide="popup-modal" type="button"
                    variant={props.variant === 'danger' ? 'danger' : 'default'}
                    onClick={props.onConfirmHandler}
            >
              {props.confirmText || "Continue"}
            </Button>
            {
              props.onCloseHandler && props.cancelText && (
                <Button data-modal-hide="popup-modal" type="button"
                        variant='primary'
                        onClick={props.onCloseHandler}
                >
                  {props.cancelText || "No, cancel"}
                </Button>
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;