import React from 'react';
import {Label} from "src/components/ui/label";
import {UTILS} from "src/commons/utils";
import {Textarea as BaseTextArea} from "src/components/ui/textarea";
import {cn} from "src/lib/utils";

function TextArea(props) {
  return (
    <div className="flex flex-col w-full gap-y-1 text-!grey-200">
      <Label className='flex flex-row text-[12px] gap-x-1' htmlFor={props.label}>
        <span className='flex flex-col'>{UTILS.toTitleCase(props.label)}</span>
        <span className='flex flex-col text-!red-600 my-auto'>*</span>
      </Label>
      <BaseTextArea
        className={cn(
          'flex flex-row outline-none text-white',
          props.className
        )}
        type='text'
        name={props.label.toLowerCase().replaceAll(' ', '_')}
        id={`${props.label.toLowerCase().replaceAll(' ', '_')}`}
        placeholder='Please input text here'
        {...props}
      />
    </div>
  );
}

export default TextArea;