import React from 'react';
import {LoaderCircle} from "lucide-react";
import {cn} from "src/lib/utils";

function Loader(props) {
  return (
    <LoaderCircle className={cn(
      'h-12 w-12 m-auto text-!grey-200 animate-spin',
      props.className
    )}
    {...props}
    />
  );
}

export default Loader;