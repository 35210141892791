import axios from "axios";
import Cookies from "js-cookie";

axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if(error && error.response && error.response.data) {
    const e = error.response.data;
    if(e && e.errors && e.errors.code && e.errors.code === 'token_not_valid') {
      console.log(window.location)
      Cookies.remove('access_token')
      // window.location.replace(window.location.href + 'expired')
    }
  }
  return Promise.reject(error);
});

export const CONFIG = {
  /*
   * Local Configs
   */
  // DOMAIN: 'localhost',
  // AUTH_FRONTEND_URL: 'http://localhost:3000',
  // APP_FRONTEND_URL: 'http://localhost:3001/app',
  // APP_BACKEND_URL: 'http://localhost:8000',

  /*
   * Production Configs
   */
  DOMAIN: 'cognatoai.com',
  AUTH_FRONTEND_URL: 'https://cognatoai.com',
  APP_FRONTEND_URL: 'https://cognatoai.com/app',
  APP_BACKEND_URL: 'https://sora.cognatoai.com',

  INTERVIEW_COST: 8,
  REVIEW_COST: 4,
}
