import React, {useEffect, useState} from 'react';
import {Badge} from "src/components/ui/badge";
import {Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle} from "src/components/ui/card";
import {Separator} from "src/components/ui/separator";
import Button from "src/components/Button";
import {DATA} from "src/data/data";
import {UTILS} from "src/commons/utils";
import {Calendar} from 'src/components/ui/calendar';
import Input from "src/components/Input";
import {CalendarDays, CalendarIcon, ChevronDown, Loader, LoaderCircle, Search} from "lucide-react";
import {Popover, PopoverContent, PopoverTrigger} from "src/components/ui/popover";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator,
  DropdownMenuTrigger
} from "src/components/ui/dropdown-menu";
import {format} from "date-fns";
import {cn} from "src/lib/utils";
import {
  Pagination,
  PaginationContent, PaginationEllipsis,
  PaginationItem, PaginationLink,
  PaginationNext,
  PaginationPrevious
} from "src/components/ui/pagination";
import Table from "src/components/Table";
import {useDispatch, useSelector} from "react-redux";
import {Link, useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {listCandidates} from "src/store/candidate/candidateActions";



const candidateTableColumns = [
  {
    id: 'email',
    header: 'Email',
    cell: ({row}) => {
      return <div>{row.email}</div>
    },
    enableSorting: false,
    enableHiding: false,
  },
  {
    id: 'first_name',
    header: 'First Name',
    cell: ({row}) => {
      return <div>{row.first_name}</div>
    },
    enableSorting: false,
    enableHiding: false,
  },
  {
    id: 'last_name',
    header: 'Last Name',
    cell: ({row}) => {
      return <div>{row.last_name}</div>
    },

  },
  {
    id: 'phone',
    header: 'Phone',
    cell: ({row}) => {
      return <div>{row.phone}</div>
    },

  },
  {
    id: 'resume',
    header: 'Resume',
    cell: ({row}) => {
      return <Link className='underline' to={row.resume} target='_blank'>Resume</Link>
    },
    enableSorting: false,
    enableHiding: false,
  },
]


const defaultFilters = {
  page: null,
  search: null,
}

function CandidateTable(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const [filterParams, setFilterParams] = useState(defaultFilters);

  // const { Candidates } = DATA;
  const { error, loading, candidates } = useSelector(state => state.listCandidates);

  useEffect(() => {
    let params = {}
    for(const key in defaultFilters) {
      if(searchParams.get(key)) {
        if(defaultFilters[key] !== null && typeof defaultFilters[key] === 'object' && searchParams.getAll(key).length > 0) {
          params[key] = searchParams.getAll(key)[0];
        }
        else {
          params[key] = searchParams.get(key);
        }
      }
    }
    setFilterParams({
      ...defaultFilters,
      ...params,
      status: (params.status && params.status.split(',')) || []
    });

    dispatch(listCandidates(params));
  }, [searchParams]);


  const updateUrl = (queryParams) => {
    let params = {
      ...filterParams,
      ...queryParams
    }

    for(const key in defaultFilters) {
      if(UTILS.isNull(params[key]) || params[key].length === 0) {
        delete params[key];
      }
    }

    const urlParams = new URLSearchParams(params);
    if(urlParams.toString().length > 0) {
      navigate('/candidates?' + urlParams.toString());
    }
  }


  const onFilterHandler = (e) => {
    e.preventDefault();
    updateUrl({});
  }

  return (
    <div className='flex flex-col w-full gap-y-4'>
      <div className='flex flex-col border-[1px] border-!grey-200/30 p-4 rounded-lg gap-y-2'>
        <div className='flex flex-row]'>Filters</div>

        <div className='flex flex-row w-[40%] gap-2'>
          <div className='flex flex-col w-full'>
            <Input label='search' placeholder='Search using candidates email'
                   onChange={(e) => setFilterParams({
                     ...filterParams,
                     search: e.target.value,
                   })}
                   defaultValue={filterParams.search}
                   hidelabel={true} />
          </div>
          <div className='flex flex-col justify-end'>
            <Button variant='default' onClick={onFilterHandler}>
              <Search className='m-auto h-[1.4rem]' />
            </Button>
          </div>
        </div>

      </div>

      <div className='w-full h-full m-auto'>
        <Table columns={candidateTableColumns} data={candidates && candidates.results} loading={loading} />
      </div>

      <div className='w-full h-full border-[0px] border-!grey-200/30 rounded-lg p-2 text-!grey-200'>
        <Pagination>
          {
            candidates && (
              <PaginationContent>
                <PaginationItem>
                  <PaginationPrevious
                    className={cn(
                      'cursor-pointer',
                      UTILS.isNull(candidates.previous) && 'hover:bg-transparent hover:text-!grey-200/60 text-!grey-200/60 cursor-not-allowed'
                    )}
                    onClick={(e) => {
                      e.preventDefault();
                      if(candidates.previous) {
                        updateUrl({
                          page: candidates.page - 1,
                        })
                      }
                    }}
                  />
                </PaginationItem>
                {
                  candidates && candidates.pages > 5 ? (
                    <>
                      {(candidates.page >= 5) && (
                        <PaginationItem>
                          <PaginationEllipsis/>
                        </PaginationItem>
                      )}

                      {
                        UTILS.range(
                          Math.max(1, candidates.page - 3),
                          Math.max(1, candidates.page - 3) + 5
                        ).map((page, index) => (
                          <PaginationItem key={index}>
                            <PaginationLink
                              href='#' isActive={candidates.page === page}
                              onClick={(e) => {
                                e.preventDefault();
                                setFilterParams({
                                  ...filterParams,
                                  page: page,
                                });
                              }}
                            >
                              {page}
                            </PaginationLink>
                          </PaginationItem>
                        ))
                      }

                      {(candidates.page < 5) && (
                        <PaginationItem>
                          <PaginationEllipsis/>
                        </PaginationItem>
                      )}
                    </>

                  ) : candidates && candidates.pages > 0 ? (
                    UTILS.range(1, candidates.pages+1).map((page, index) => (
                      <PaginationItem key={index}>
                        <PaginationLink
                          className='cursor-pointer'
                          isActive={candidates.page === page}
                          onClick={(e) => {
                            e.preventDefault();
                            updateUrl({
                              page: page,
                            })
                          }}
                        >
                          {page}
                        </PaginationLink>
                      </PaginationItem>
                    ))
                  ) : (
                    <PaginationItem>
                      <PaginationEllipsis />
                    </PaginationItem>
                  )
                }

                <PaginationItem>
                  <PaginationNext
                    className={cn(
                      'cursor-pointer',
                      UTILS.isNull(candidates.next) && 'hover:bg-transparent hover:text-!grey-200/60 text-!grey-200/60 cursor-not-allowed'
                    )}
                    onClick={(e) => {
                      e.preventDefault();
                      if(candidates.next) {
                        updateUrl({
                          page: candidates.page + 1,
                        })
                      }
                    }}
                  />
                </PaginationItem>
              </PaginationContent>
            )
          }
        </Pagination>
      </div>
    </div>
  );
}

function CandidateTableCard(props) {
  return (
    <Card className='border-0'>
      <CardHeader>
        <CardTitle>Candidates</CardTitle>
        <CardDescription>History of all the scheduled candidates.</CardDescription>
      </CardHeader>

      <Separator className='mb-[4vh] w-[98%] mx-auto' decorative />

      <CardContent>
        <div className='flex flex-col w-full'>
          <div className='flex flex-row'>
            <CandidateTable />
          </div>
        </div>

      </CardContent>

      <CardFooter>

      </CardFooter>
    </Card>
  )
}

function CandidateScreen(props) {
  return (
    <div className='h-full w-full'>
      <CandidateTableCard />
    </div>
  );
}

export default CandidateScreen;
