import React, {useEffect, useState} from 'react';
import {Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle} from "src/components/ui/card";
import {cn} from "src/lib/utils";
import Input from "src/components/Input";
import TextArea from "src/components/TextArea";
import {useDispatch, useSelector} from "react-redux";
import toast from "react-hot-toast";
import {listCandidates} from "src/store/candidate/candidateActions";
import Button from "src/components/Button";
import {Search} from "lucide-react";
import {Separator} from "src/components/ui/separator";
import Table from "src/components/Table";
import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem, PaginationLink, PaginationNext,
  PaginationPrevious
} from "src/components/ui/pagination";
import {UTILS} from "src/commons/utils";
import {format} from "date-fns";
import {Badge} from "src/components/ui/badge";
import {
  Eye,
  EyeOff
} from "lucide-react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {listInterviews} from "src/store/interview/interviewActions";
import {createReview, listReviews, retrieveReview} from "src/store/review/reviewActions";
import {Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle} from "src/components/ui/sheet";
import Loading from "src/components/Loading";
import Loader from "src/components/Loader";
import Modal from "src/components/Modal";
import {INTERVIEW_CREATE_RESET} from "src/store/interview/interviewConstants";
import {REVIEW_CREATE_RESET} from "src/store/review/reviewConstants";

const reviewOrder = [
  "overall_impression",
  "relevance_of_experience",
  "technical_and_soft_skills",
  "accomplishments_and_impact",
  "formatting_and_readability",
  "tips",
  "roast"
];

function ReviewSheet(props) {
  const dispatch = useDispatch();
  const { error, loading, review } = useSelector(state => state.retrieveReview)

  useEffect(() => {
    if(review == null && props.review) {
      dispatch(retrieveReview(props.review))
    } else if(review && props.review && props.review !== review.id) {
      dispatch(retrieveReview(props.review))
    }
  }, []);


  return (
    <Sheet open={props.open} onOpenChange={(open) => props.onOpenChange(open)}>
      <SheetContent className='bg-!black text-white sm:max-w-[60vw] max-w-[80vw] border-!black overflow-auto'>
        <SheetHeader>
          <SheetTitle className='text-white'>Review {review?.id.split('-')[0]}</SheetTitle>
          {
            loading ? (
              <Loading />
            ) : review ? (
              <SheetDescription className='text-!off-white'>
                {
                  reviewOrder.map((r, idx) => review.review[r] && (
                    <div key={idx} className='mt-4'>
                      <h2 className='text-lg'>{UTILS.toTitleCase(r)}</h2>
                      <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        {
                          review.review[r].map((rev, index) => (
                            <li key={index} className='text-!off-white/70 circle'>{rev}</li>
                          ))
                        }
                      </ul>
                    </div>
                  ))
                }
              </SheetDescription>
            ) : (
              <SheetDescription className='text-!off-white'>
                Error
              </SheetDescription>
            )
          }
        </SheetHeader>
      </SheetContent>
    </Sheet>
  )
}

const defaultFilters = {
  page: null,
  search: null,
  status: [],
}

function ReviewTable(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { error, loading, reviews } = useSelector(state => state.listReviews);

  const [searchParams, setSearchParams] = useSearchParams();
  const [filterParams, setFilterParams] = useState(defaultFilters);
  const [selectedReview, setSelectedReview] = useState();


  const reviewTableColumns = [
    {
      id: 'id',
      header: 'Id',
      cell: ({row}) => {
        return <div>{row.id.split('-')[0]}</div>
      },
      enableSorting: false,
      enableHiding: false,
    },
    {
      id: 'created',
      header: 'Created',
      cell: ({row}) => {
        return <div>{format(new Date(row.created), 'dd LLL yyyy HH:MM:SS')}</div>
      },
      enableSorting: false,
      enableHiding: false,
    },
    {
      id: 'role',
      header: 'Role',
      cell: ({row}) => {
        return <div>{row.role || row.original.role}</div>
      },

    },
    {
      id: 'status',
      header: 'Status',
      cell: ({row}) => {
        return <Badge>{UTILS.toTitleCase(row.status || row.original.status)}</Badge>
      },
      enableSorting: false,
      enableHiding: false,
    },
    {
      id: 'action',
      header: 'Action',
      cell: ({row}) => {
        return (
          <div className='flex flex-row'>
           <span className={cn('p-2 hover:bg-!grey-200/20 rounded-md', row.status === 'successful' ? 'hover:cursor-pointer' : 'hover:cursor-not-allowed')}
                 onClick={() => {
                   if(row.status === 'successful') setSelectedReview(row.id)
                 }}
           >
             {
               row.status === 'successful' ? (
                 <Eye className='h-4 w-4' />
               ) : (
                 <EyeOff className='h-4 w-4 text-!grey-200' />
               )
             }
           </span>
          </div>
        )
      }
    }
  ]

  const updateUrl = (queryParams) => {
    let params = {
      ...filterParams,
      ...queryParams
    }

    const urlParams = new URLSearchParams(params);
    if(urlParams.toString().length > 0) {
      navigate('/resume/review?' + urlParams.toString());
    }
  }

  useEffect(() => {
    let params = {}

    for(const key in defaultFilters) {
      if(searchParams.get(key)) {
        if(defaultFilters[key] !== null && typeof defaultFilters[key] === 'object' && searchParams.getAll(key).length > 0) {
          params[key] = searchParams.getAll(key)[0];
        }
        else {
          params[key] = searchParams.get(key);
        }
      }
    }
    setFilterParams({
      ...defaultFilters,
      ...params,
      status: (params.status && params.status.split(',')) || []
    });

    dispatch(listReviews(params));
  }, [searchParams]);

  return (
    <div className='h-full w-full'>
      {
        selectedReview != null && (
          <ReviewSheet open={true} review={selectedReview} onOpenChange={(open) => {
            if(!open) setSelectedReview(null)
          }} />
        )
      }
      <div className='w-full h-full m-auto'>
        <Table columns={reviewTableColumns} data={reviews && reviews.results} loading={loading} />
      </div>

      <div className='w-full h-full border-[0px] border-!grey-200/30 rounded-lg p-2 text-!grey-200'>
        <Pagination>
          {
            reviews && (
              <PaginationContent>
                <PaginationItem>
                  <PaginationPrevious
                    className={cn(
                      'cursor-pointer',
                      UTILS.isNull(reviews.previous) && 'hover:bg-transparent hover:text-!grey-200/60 text-!grey-200/60 cursor-not-allowed'
                    )}
                    onClick={(e) => {
                      e.preventDefault();
                      if(reviews.previous) {
                        updateUrl({
                          page: reviews.page - 1,
                        })
                      }
                    }}
                  />
                </PaginationItem>
                {
                  reviews && reviews.pages > 5 ? (
                    <>
                      {(reviews.page >= 5) && (
                        <PaginationItem>
                          <PaginationEllipsis/>
                        </PaginationItem>
                      )}

                      {
                        UTILS.range(
                          Math.max(1, reviews.page - 3),
                          Math.max(1, reviews.page - 3) + 5
                        ).map((page, index) => (
                          <PaginationItem key={index}>
                            <PaginationLink
                              href='#' isActive={reviews.page === page}
                              onClick={(e) => {
                                e.preventDefault();
                                setFilterParams({
                                  ...filterParams,
                                  page: page,
                                });
                              }}
                            >
                              {page}
                            </PaginationLink>
                          </PaginationItem>
                        ))
                      }

                      {(reviews.page < 5) && (
                        <PaginationItem>
                          <PaginationEllipsis/>
                        </PaginationItem>
                      )}
                    </>

                  ) : reviews && reviews.pages > 0 ? (
                    UTILS.range(1, reviews.pages+1).map((page, index) => (
                      <PaginationItem key={index}>
                        <PaginationLink
                          className='cursor-pointer'
                          isActive={reviews.page === page}
                          onClick={(e) => {
                            e.preventDefault();
                            updateUrl({
                              page: page,
                            })
                          }}
                        >
                          {page}
                        </PaginationLink>
                      </PaginationItem>
                    ))
                  ) : (
                    <PaginationItem>
                      <PaginationEllipsis />
                    </PaginationItem>
                  )
                }

                <PaginationItem>
                  <PaginationNext
                    className={cn(
                      'cursor-pointer',
                      UTILS.isNull(reviews.next) && 'hover:bg-transparent hover:text-!grey-200/60 text-!grey-200/60 cursor-not-allowed'
                    )}
                    onClick={(e) => {
                      e.preventDefault();
                      if(reviews.next) {
                        updateUrl({
                          page: reviews.page + 1,
                        })
                      }
                    }}
                  />
                </PaginationItem>
              </PaginationContent>
            )
          }
        </Pagination>
      </div>
    </div>
  )
}

function ReviewTableCard(props) {


  return (
    <Card className='border-0 h-full w-full'>

      <CardContent className='h-full w-full'>
        <ReviewTable />
      </CardContent>

      <CardFooter>

      </CardFooter>
    </Card>
  )
}

function ResumeSubmitCard(props) {
  const { defaultCandidateValue, onResumeUpload, onSubmit } = props;

  const dispatch = useDispatch();

  const [resume, setResume] = useState();
  const [showModal, setShowModal] = useState(false);

  const {error, loading, review} = useSelector(state => state.createReview)

  useEffect(() => {
    if(error) {
      toast.error(error.message)
    } else if(review) {
      setShowModal(true)
    }
  }, [error, review]);

  const handleFile = (e) => {
    let file = e.target.files[0];

    const fileType = file["type"];
    const validResumeTypes = ["application/pdf"];
    if(file.size / 1024 / 1024 > 4) {
      toast.error('Please upload file < 4MB');
      // e.target.files = new FileList();
      // TODO: remove file
    } else if (validResumeTypes.includes(fileType)) {
      setResume(file);
    } else {
      toast.error('Please upload a pdf.')
    }
  };

  const onSubmitHandler = (e) => {
    e.preventDefault()

    let data = UTILS.buildJsonFromForm(e.target);
    const formData = new FormData();
    delete data['resume']
    formData.append('resume', resume);
    formData.append('data', JSON.stringify(data));

    dispatch(createReview(formData))
  }

  return (
    <>
      {
        showModal && (
          <Modal message='Successfully requested review. Please refresh the page in a few seconds to get your result!'
                 variant='success'
                 onConfirmHandler={() => setShowModal(false)}
                 onCloseHandler={() => {
                   setShowModal(false);
                   dispatch({
                     type: REVIEW_CREATE_RESET,
                   });
                 }}
          />
        )
      }
      <Card className='flex flex-col w-full'>
        <CardHeader>
          <CardTitle>Submit Resume</CardTitle>
          <CardDescription>Submit your resume to get a roast, review and tips.</CardDescription>
        </CardHeader>

        <CardContent>
          <form className={cn(
            'flex sm:flex-row flex-col w-full gap-6',
            props.className
          )}
                id='resumeForm'
                onSubmit={onSubmitHandler}
          >
            <div className='flex sm:flex-row flex-col md:w-[60%] w-full gap-4'>
              <div className='flex flex-col w-full'>
                <Input label='Role' type='text' placeholder='Software Engineer' required />
              </div>
              <div className='flex flex-col w-full gap-y-1'>
                <Input className='display-none file:text-white file:bg-!grey-200/20 file:mr-2 file:rounded-md text-!grey-200 hover:file:bg-!grey-200/10 file:hover:cursor-pointer'
                       onChange={handleFile} label='resume' type='file' accept='application/pdf' placeholder={'WTF'} required={true} />
              </div>
            </div>

            <div className='flex flex-col justify-end'>
              <Button variant='default' loading={loading}>
                Submit
              </Button>
            </div>
          </form>
        </CardContent>
      </Card>
    </>
  )
}

function ResumeReviewScreen(props) {
  return (
    <div className='flex flex-col w-full h-full gap-6'>
      <div className='flex flex-row w-full h-full px-6'>
        <ResumeSubmitCard />
      </div>
      <div className='flex flex-row w-full h-full'>
        <ReviewTableCard />
      </div>
    </div>
  );
}

export default ResumeReviewScreen;