// userConstants.js
export const USER_CREATE_REQUEST = 'USER_CREATE_REQUEST';
export const USER_CREATE_SUCCESS = 'USER_CREATE_SUCCESS';
export const USER_CREATE_FAIL = 'USER_CREATE_FAIL';
export const USER_CREATE_RESET = 'USER_CREATE_RESET';

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL';
export const USER_UPDATE_RESET = 'USER_UPDATE_RESET';

export const USER_RETRIEVE_REQUEST = 'USER_RETRIEVE_REQUEST';
export const USER_RETRIEVE_SUCCESS = 'USER_RETRIEVE_SUCCESS';
export const USER_RETRIEVE_FAIL = 'USER_RETRIEVE_FAIL';
export const USER_RETRIEVE_RESET = 'USER_RETRIEVE_RESET';

export const USER_CHANGE_PASSWORD_REQUEST = 'USER_CHANGE_PASSWORD_REQUEST';
export const USER_CHANGE_PASSWORD_SUCCESS = 'USER_CHANGE_PASSWORD_SUCCESS';
export const USER_CHANGE_PASSWORD_FAIL = 'USER_CHANGE_PASSWORD_FAIL';
export const USER_CHANGE_PASSWORD_RESET = 'USER_CHANGE_PASSWORD_RESET';
