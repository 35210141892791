import {CONFIG} from "src/commons/config";
import {UTILS} from "src/commons/utils";


export const RECORDING_APIS = {
  retrieve: (id, configs) => {
    return UTILS.request({
      baseURL: CONFIG.APP_BACKEND_URL,
      url: `/api/v1/recordings/interviews/${id}/`,
      method: 'GET',
      ...configs
    })
  },
}
