import React, {useEffect, useState} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {
  House,
  MonitorDot,
  UsersRound,
  Building2,
  Settings,
  CirclePlus,
  Power,
  CircleHelp,
  FileUser
} from 'lucide-react';
import {cn} from "src/lib/utils";
import {UTILS} from "src/commons/utils";
import GradientButton from "src/components/GradientButton";
import {Button} from "src/components/ui/button";
import GradientText from "src/components/GradientText";


const menu = [
  {
    label: 'home',
    title: 'Home',
    icon: <House />,
    link: '',
  },
  {
    label: 'interviews',
    title: 'Interviews',
    icon: <MonitorDot />,
    link: '/interviews',
  },
  {
    label: 'candidates',
    title: 'Candidates',
    icon: <UsersRound />,
    link: '/candidates',
  },
  {
    label: 'organizations',
    title: 'Organizations',
    icon: <Building2 />,
    link: '/organizations',
  },
  {
    label: 'general',
    title: 'General',
    icon: null,
    link: '/organizations',
  },
  // {
  //   label: 'Members',
  //   icon: null,
  //   link: '/organizations/members',
  // },
  {
    label: 'billing',
    title: 'Billing',
    icon: null,
    link: '/organizations/billing',
  },
  {
    label: 'review',
    title: 'Resume Roast',
    icon: <FileUser />,
    link: '/resume/review',
    new: true,
  },
  {
    label: 'settings',
    title: 'Settings',
    icon: <Settings />,
    link: '/settings',
  }
]

function NavigationMenu(props) {
  const location = useLocation();
  const [selected, setSelected] = useState('Home');

  useEffect(() => {
    const page = location.pathname.split('/').pop();
    if(page.length === 0) setSelected('home');
    else if(page === 'organizations') {
      setSelected('general');
    }
    else {
      setSelected(page.toLowerCase());
    }
  }, [location]);


  return (
    <div className='flex flex-col text-!grey-200 gap-y-1'>
      {
        menu.map((item, index) => (
          <Link key={index} to={item.link}>
            <div key={index} className={cn(
              'flex flex-row hover:bg-!grey-200/10 rounded-md px-4 py-2 gap-x-2 text-[0.9rem] justify-between',
              item.label === selected && 'bg-!grey-200/10 font-bold'
            )}>
              <div className='flex flex-row'>
                <span className='flex flex-col my-auto mr-2 h-[1.4rem] w-[1.8vw]'>{item.icon}</span>
                <span className='flex flex-col my-auto'>{item.title}</span>
              </div>
              {
                item.new && (
                  <div className='flex flex-row italic text-[12px] my-auto bg-clip-text text-transparent bg-gradient-to-r from-purple-500 via-blue-500 to-cyan-500 font-semibold'>
                    {/*<GradientText text="New" />*/}
                    New
                  </div>
                )
              }
            </div>
          </Link>
        ))
      }
    </div>
  )
}

function Sider(props) {
  const navigate = useNavigate();

  return (
    <div className={cn(
      'h-[90vh] w-[300px] border-r-[1px] border-!grey-200/20 py-6 px-4 justify-between',
      props.className
    )}>
      <div className='flex flex-col h-full w-full justify-between'>

        <div className='flex flex-col gap-y-6'>
          <NavigationMenu />

          <GradientButton
            text={(
              <span className='flex flex-row w-fit m-auto gap-x-1'>
                <span className='flex flex-col h-[1.2rem] m-auto'><CirclePlus /></span>
                <span>Create</span>
              </span>
            )}
            onClick={() => navigate('')}
          />
        </div>

        <div className='flex flex-row w-full gap-x-2'>
          <Button className='flex flex-col h-full w-full py-3' variant='danger' onClick={() => navigate('/logout') }>
            <Power className='h-[1.2rem]' />
          </Button>

          <Button className='flex flex-col h-full w-full py-3' variant='secondary'>
            <CircleHelp className='h-[1.2rem]' />
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Sider;