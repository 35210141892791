import React, {useEffect, useState} from 'react';
import {cn} from "src/lib/utils";
import Input from "src/components/Input";
import TextArea from "src/components/TextArea";
import toast from "react-hot-toast";
import {UTILS} from "src/commons/utils";
import {Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle} from "src/components/ui/card";
import {Separator} from "src/components/ui/separator";
import Button from "src/components/Button";
import {DATA} from "src/data/data";
import {useDispatch, useSelector} from "react-redux";
import {retrieveOrganization, updateOrganization} from "src/store/organization/organizationActions";
import {LoaderCircle} from "lucide-react";
import Loader from "src/components/Loader";
import {ORGANIZATION_UPDATE_RESET} from "src/store/organization/organizationConstants";

function OrganizationForm(props) {
  const { organization } = props


  return (
    <form className={cn(
      'flex flex-col',
      props.className
    )}
    id='orgForm'
    {...props}
    >
      <div className='flex flex-row  font-semibold text-lg pb-4'>
        Organization Details
      </div>

      <div className='flex flex-col md:w-[60%] w-full gap-4'>
        <div className='flex flex-row w-full gap-x-4'>
          <Input label='id' type='text' placeholder='Id' defaultValue={organization.id} disabled required />
        </div>

        <div className='flex flex-row w-full'>
          <Input label='Name' type='text' placeholder='Name' defaultValue={organization.name} required />
        </div>

        <div className='flex flex-row w-full gap-x-4'>
          <TextArea label='Description' defaultValue={organization.description} />
        </div>
      </div>
    </form>
  );
}

function OrganizationCard(props)  {
  const { organization } = props;

  const dispatch = useDispatch();

  const { error, loading, organization: updatedOrganization } = useSelector(state => state.updateOrganization);

  useEffect(() => {
    if(error) {
      toast.error(error.message)
      setTimeout(() => {
        dispatch({
          type: ORGANIZATION_UPDATE_RESET
        })
      }, 5000)
    } else if(updatedOrganization) {
      toast.success('Successfully updated!')
      setTimeout(() => {
        dispatch({
          type: ORGANIZATION_UPDATE_RESET
        })
      }, 5000)
    }
  }, [error, updatedOrganization]);


  const onSubmitHandler = (e) => {
    e.preventDefault();

    const data = UTILS.buildJsonFromForm(e.target);
    dispatch(updateOrganization(organization.id, data));
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Organization</CardTitle>
        <CardDescription>Update details of the currently selected organization.</CardDescription>
      </CardHeader>

      <Separator className='mb-[4vh] md:w-[98%] w-full mx-auto' decorative />

      <CardContent>
        <div className='flex flex-col'>
          <OrganizationForm organization={organization}  onSubmit={onSubmitHandler} />
        </div>

      </CardContent>
      <CardFooter>
        <div className='flex flex-row gap-x-4'>
          <Button type='submit' form='orgForm' variant='default' loading={loading}>
            Update
          </Button>
        </div>
      </CardFooter>
    </Card>
  )
}

function OrganizationScreen(props) {
  const dispatch = useDispatch();

  const { error, loading, organization } = useSelector(state => state.retrieveOrganization)

  useEffect(() => {
    if(error) {
      toast.error(error.message);
    } else if(!error && !loading && !organization) {
      dispatch(retrieveOrganization(
        UTILS.getOrganization()
      ))
    }
  }, [error, loading, organization]);

  return (
    <div className='h-full w-full'>
      {
        loading ? (
          <Loader />
        ) : organization ? (
          <OrganizationCard organization={organization} />
        ) : (
          <></>
        )
      }
    </div>
  );
}

export default OrganizationScreen;