import React, {useEffect, useRef, useState} from 'react';

import {AUDIO_STATUS} from "../constants/enums";


export function useTimer(time) {
  const [callback, setCallback] = useState(null);
  const [timedelta, setTimedelta] = useState(time);
  const [finished, setFinished] = useState(timedelta <= 0);

  const calculateTimeLeft = () => {
    return {
      hours: Math.floor((timedelta / (60 * 60)) % 24),
      minutes: Math.floor((timedelta / 60) % 60),
      seconds: Math.floor(timedelta % 60),
    }
  }
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimedelta(Math.max(0, timedelta-1));
      // setTimeLeft(calculateTimeLeft())
    }, 1000)

    if(finished) clearTimeout(timer) // to stop timeout when timer finishes
    return () => clearTimeout(timer)
  });

  useEffect(() => {
    setTimeLeft(calculateTimeLeft())
    setFinished(timedelta <= 0);
  }, [timedelta]);

  useEffect(() => {
    if(finished && callback) {
      callback();
    }
  }, [finished]);



  return {
    timeLeft,
    finished,
    setTimer: (time, cb=null) => {
      setTimedelta(time);
      setCallback(cb);
    }
  };
}


export function useAudioPlayer(
  src,
  playbackRate = 1.0,
  onStart = null,
  onEnd = null,
  onLoaded = null,
  onPause = null,
) {
  const audioRef = useRef(null);
  const [audioStatus, setAudioStatus] = useState(AUDIO_STATUS.INACTIVE);

  const audioStart = (event) => {
    setAudioStatus(AUDIO_STATUS.PLAYING);
    if (onStart) onStart();
  };

  const audioLoaded = (event) => {
    // setAudioStatus(AUDIO_STATUS.READY);
    if (onLoaded) onLoaded();
  };

  const audioPause = (event) => {
    setAudioStatus(AUDIO_STATUS.PAUSED);
    if (onPause) onPause();
  }

  const audioEnd = (event) => {
    setAudioStatus(AUDIO_STATUS.INACTIVE);
    if (onEnd) onEnd();
  };

  useEffect(() => {
    if(src) {
      let audio = new Audio(src);
      audioRef.current = audio;
      audioRef.current.playbackRate = playbackRate;
      audioRef.current.onplay = audioStart;
      audioRef.current.onended = audioEnd;
      audioRef.current.onpause = audioPause;
      audioRef.current.onloadeddata = audioLoaded;
    }
  }, [src])

  const play = () => {
    if(audioRef.current) {
      audioRef.current.play();
    }
  };

  const pause = () => {
    if (audioRef.current) {
      audioRef.current.pause();
    }
  };

  const stop = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      delete audioRef.current;
    }
  };

  return {
    audioStatus,
    play,
    pause,
    stop,
  };
}
