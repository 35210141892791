import React from 'react';
import {cn} from "src/lib/utils";
import {Inbox} from "lucide-react";

function NoData(props) {
  return (
    <div className={cn(
      'flex flex-col h-full w-full m-auto text-!grey-200/60 text-lg font-normal gap-y-2'
    )}>
      <Inbox className='flex flex-row m-auto' />
      <span className='flex flex-row m-auto'>No Data</span>
    </div>
  );
}

export default NoData;