import toast from "react-hot-toast";

import Cookies from "js-cookie";
import { Loader } from "lucide-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CONFIG } from "src/commons/config";
import { UTILS } from "src/commons/utils";
import ExpiredModal from "src/components/ExpiredModal";
import { ScrollArea } from "src/components/ui/scroll-area";
import Content from "src/layouts/Content";
import Header from "src/layouts/Header";
import Layout from "src/layouts/Layout";
import Sider from "src/layouts/Sider";
import Router from "src/router/Router";
import { retrieveUser } from "src/store/user/userActions";

function App() {
  const dispatch = useDispatch();
  const [init, setInit] = useState(false);

  const { error, loading, user } = useSelector((state) => state.retrieveUser);

  useEffect(() => {
    const token = UTILS.getToken();
    if (!token) {
      window.location.replace(CONFIG.AUTH_FRONTEND_URL);
      return;
    }

    if (error) {
      toast.error("Authentication failed! Please log in again.");
    } else if (user) {
      Cookies.set("org_id", user.organizations[0].id);
      setInit(true);
    } else if (!loading) {
      dispatch(retrieveUser());
    }
  }, [user, error, loading]);

  return (loading || !init) ? (
    <div className="h-screen w-screen m-auto">
      <Loader />
    </div>
  ) : user ? (
    <Layout className="flex flex-col bg-!black font-Inter dark">
      <Header className="flex flex-row" />

      <Layout className="flex flex-row">
        <Sider className="md:flex hidden flex-col" />

        <ScrollArea className="flex flex-row w-full h-[90vh]">
          <Content className="flex flex-row md:p-10 sm:p-4 md:mt-0 sm:mt-8 p-2 mt-4">
            <Router />
          </Content>
        </ScrollArea>
      </Layout>

      {/*<Footer className='flex flex-row' />*/}
    </Layout>
  ) : (
    <ExpiredModal />
  );
}

export default App;
