import React, {useEffect} from 'react';
import {Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle} from "src/components/ui/card";
import {Separator} from "src/components/ui/separator";
import Button from "src/components/Button";
import {cn} from "src/lib/utils";
import Input from "src/components/Input";
import TextArea from "src/components/TextArea";
import toast from "react-hot-toast";
import {UTILS} from "src/commons/utils";
import {DATA} from "src/data/data";
import {useDispatch, useSelector} from "react-redux";
import {changePassword, updateUser} from "src/store/user/userActions";
import {USER_UPDATE_RESET} from "src/store/user/userConstants";

function ProfileCard(props) {
  const dispatch = useDispatch();
  // const {user} = DATA;

  // User is always fetched in App component
  const { user } = useSelector(state => state.retrieveUser);
  const { error, loading, user: updatedUser } = useSelector(state => state.updateUser);

  useEffect(() => {
    if(error) {
      toast.error(error.message);
    } else if(updatedUser) {
      toast.success('Profile updated successfully!');
      dispatch({
        type: USER_UPDATE_RESET,
      })
    }
  }, [error, loading, updatedUser]);


  const onSubmitHandler = (e) => {
    e.preventDefault();


    const data = UTILS.buildJsonFromForm(e.target);
    delete data['email'];

    dispatch(updateUser(data));
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Update Profile</CardTitle>
        {/*<CardDescription>Update details of the currently selected organization.</CardDescription>*/}
      </CardHeader>

      <Separator className='mb-[4vh] w-[98%] mx-auto' decorative />

      <CardContent>
        <div className='flex flex-col'>
          <form className={cn(
            'flex flex-col',
            props.className
          )}
            id='profileForm'
            onSubmit={onSubmitHandler}
            {...props}
          >
            {/*<div className='flex flex-row  font-semibold text-lg pb-4'>*/}
            {/*  Update Profile*/}
            {/*</div>*/}

            <div className='flex flex-col md:w-[60%] w-full gap-4'>
              <div className='flex flex-row w-full gap-x-4'>
                <div className='flex flex-col w-full'>
                  <Input label='first_name' type='text' placeholder='First Name' defaultValue={user.first_name} required />
                </div>

                <div className='flex flex-col w-full'>
                  <Input label='last_name' type='text' placeholder='Last Name' defaultValue={user.last_name} required />
                </div>
              </div>

              <div className='flex flex-row w-full gap-x-4'>
                <div className='flex flex-col w-full'>
                  <Input label='email' type='email' placeholder='Email' defaultValue={user.email} disabled={true} required />
                </div>

                <div className='flex flex-col w-full'>
                  <Input label='phone' type='phone' defaultValue={user.phone} placeholder='+919876543210' validate='[+]{1}[1-9]{1}[0-9]{0,2}[1-9]{1}[0-9]{6,14}' required />
                </div>
              </div>
            </div>
          </form>
        </div>

      </CardContent>
      <CardFooter>
        <div className='flex flex-row gap-x-4'>
          <Button type='submit' form='profileForm' variant='default' loading={loading}>
            Update
          </Button>
        </div>
      </CardFooter>
    </Card>
  )
}

function PasswordCard(props) {
  const dispatch = useDispatch();

  const { error, loading, success } = useSelector(state => state.changePassword);

  useEffect(() => {
    if(error) {
      toast.error(error.message);
    } else if(success) {
      toast.success('Password changed successfully!');
    }
  }, [error, loading, success]);


  const onSubmitHandler = (e) => {
    e.preventDefault();

    const data = UTILS.buildJsonFromForm(e.target);
    dispatch(changePassword(data));
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Update Password</CardTitle>
        {/*<CardDescription>Update details of the currently selected organization.</CardDescription>*/}
      </CardHeader>

      <Separator className='mb-[4vh] w-[98%] mx-auto' decorative />

      <CardContent>
        <div className='flex flex-col'>
          <form className={cn(
            'flex flex-col',
            props.className
          )}
            id='passwordForm'
            onSubmit={onSubmitHandler}
            {...props}
          >
            {/*<div className='flex flex-row  font-semibold text-lg pb-4'>*/}
            {/*  Organization Details*/}
            {/*</div>*/}

            <div className='flex flex-col md:w-[60%] w-full gap-4'>
              <div className='flex flex-row w-full gap-x-4'>
                <Input label='current_password' type='password' placeholder='Current Password' required />
              </div>

              <div className='flex flex-row w-full'>
                <Input label='new_password' type='password' placeholder='New Password' required />
              </div>
            </div>
          </form>
        </div>

      </CardContent>
      <CardFooter>
        <div className='flex flex-row gap-x-4'>
          <Button type='submit' form='passwordForm' variant='default' loading={loading}>
            Update
          </Button>
        </div>
      </CardFooter>
    </Card>
  );
}

function SettingsScreen(props) {
  const dispatch = useDispatch();

  return (
    <div className='flex flex-col w-full gap-y-6'>
      <PasswordCard />
      <ProfileCard />
    </div>
  );
}

export default SettingsScreen;