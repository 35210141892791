// subscriptionConstants.js
export const SUBSCRIPTION_CREATE_REQUEST = 'SUBSCRIPTION_CREATE_REQUEST';
export const SUBSCRIPTION_CREATE_SUCCESS = 'SUBSCRIPTION_CREATE_SUCCESS';
export const SUBSCRIPTION_CREATE_FAIL = 'SUBSCRIPTION_CREATE_FAIL';
export const SUBSCRIPTION_CREATE_RESET = 'SUBSCRIPTION_CREATE_RESET';

export const SUBSCRIPTION_RETRIEVE_REQUEST = 'SUBSCRIPTION_RETRIEVE_REQUEST';
export const SUBSCRIPTION_RETRIEVE_SUCCESS = 'SUBSCRIPTION_RETRIEVE_SUCCESS';
export const SUBSCRIPTION_RETRIEVE_FAIL = 'SUBSCRIPTION_RETRIEVE_FAIL';
export const SUBSCRIPTION_RETRIEVE_RESET = 'SUBSCRIPTION_RETRIEVE_RESET';

export const SUBSCRIPTION_ACTIVE_REQUEST = 'SUBSCRIPTION_ACTIVE_REQUEST';
export const SUBSCRIPTION_ACTIVE_SUCCESS = 'SUBSCRIPTION_ACTIVE_SUCCESS';
export const SUBSCRIPTION_ACTIVE_FAIL = 'SUBSCRIPTION_ACTIVE_FAIL';
export const SUBSCRIPTION_ACTIVE_RESET = 'SUBSCRIPTION_ACTIVE_RESET';

export const SUBSCRIPTION_LIST_REQUEST = 'SUBSCRIPTION_LIST_REQUEST';
export const SUBSCRIPTION_LIST_SUCCESS = 'SUBSCRIPTION_LIST_SUCCESS';
export const SUBSCRIPTION_LIST_FAIL = 'SUBSCRIPTION_LIST_FAIL';
export const SUBSCRIPTION_LIST_RESET = 'SUBSCRIPTION_LIST_RESET';
