import {UTILS} from "src/commons/utils";
import {CONFIG} from "src/commons/config";


export const ORGANIZATION_APIS = {
  create: (data, configs) => {
    return UTILS.request({
      baseURL: CONFIG.APP_BACKEND_URL,
      url: `/api/v1/organizations/`,
      method: 'POST',
      data: data,
      ...configs
    })
  },

  update: (id, data, configs) => {
    return UTILS.request({
      baseURL: CONFIG.APP_BACKEND_URL,
      url: `/api/v1/organizations/${id}/`,
      method: 'PATCH',
      data: data,
      ...configs
    })
  },

  retrieve: (id, configs) => {
    return UTILS.request({
      baseURL: CONFIG.APP_BACKEND_URL,
      url: `/api/v1/organizations/${id}/`,
      method: 'GET',
      ...configs
    })
  },

  list: (params, configs) => {
    return UTILS.request({
      baseURL: CONFIG.APP_BACKEND_URL,
      url: `/api/v1/organizations/`,
      method: 'GET',
      params: params,
      ...configs
    })
  }
}