import React from 'react';
import {cn} from "src/lib/utils";

function Layout(props) {
  return (
    <div
      className={cn(
        'h-full w-full',
        props.className
      )}
      {...props}
    >
      {props.children}
    </div>
  );
}

export default Layout;
