import React from 'react';
import {Label} from "src/components/ui/label";
import {Input as BaseInput} from "src/components/ui/input";
import {UTILS} from "src/commons/utils";
import {cn} from "src/lib/utils";

function Input(props) {
  return (
    <div className="flex flex-col w-full gap-y-1 text-!grey-200">
      {
        !props.hidelabel ? (
          <Label className='flex flex-row text-[12px] gap-x-1'
                 htmlFor={`${props.label.toLowerCase().replaceAll(' ', '_')}`}>
            <span className='flex flex-col'>{UTILS.toTitleCase(props.label)}</span>
            {
              props.required && (
                <span className='flex flex-col text-!red-600 my-auto'>*</span>
              )
            }
          </Label>
        ) : (
          <div className='flex flex-row flex-1'></div>
        )
      }
      <BaseInput
        className={cn(
          'flex flex-row outline-none text-white',
          props.className
        )}
        type='text'
        name={props.label.toLowerCase().replaceAll(' ', '_')}
        id={`${props.label.toLowerCase().replaceAll(' ', '_')}`}
        placeholder='Input'
        {...props}
      />
    </div>
  );
}

export default Input;