import {UTILS} from "src/commons/utils";
import {CONFIG} from "src/commons/config";


export const FEEDBACK_APIS = {
  retrieve: (id, configs) => {
    return UTILS.request({
      baseURL: CONFIG.APP_BACKEND_URL,
      url: `/api/v2/feedbacks/interviews/${id}`,
      method: 'GET',
      ...configs
    })
  },
}
