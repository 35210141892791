import React from 'react';
import { CONFIG } from 'src/commons/config';
import toast, {useToaster} from "react-hot-toast";
import {
  Code,
  FileQuestion,
  MessageCircleReply,
  Presentation,
  User,
  ArrowDownToLine,
  LoaderCircle,
  AudioLines,
  Video,
  AArrowUp, AlertCircle, Activity, ChartColumnBig
} from 'lucide-react'
import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from "src/components/ui/accordion";
import { Bar, BarChart, CartesianGrid, XAxis } from "recharts"
import { Badge } from "src/components/ui/badge";
import { UTILS } from "src/commons/utils";
import {Card, CardContent, CardFooter, CardHeader, CardTitle} from "src/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "src/components/ui/tabs";
import { Label } from "src/components/ui/label";
import { DATA } from "src/data/data";
import { cn } from "src/lib/utils";
import { Button } from "src/components/ui/button"; // Import Button from Shadcn
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { retrieveInterview } from 'src/store/interview/interviewActions';
import { retrieveFeedback } from 'src/store/feedback/feedbackActions';
import { dowloadInterviewResult } from 'src/store/interview/interviewActions';
import Loader from 'src/components/Loader';
import { useAudioPlayer } from 'src/commons/hooks';
import {AUDIO_STATUS, RECORDING_STATUS} from 'src/constants/enums';
import HLSPlayer from "src/components/HLSPlayer";
import {Alert, AlertDescription, AlertTitle} from "src/components/ui/alert";
import {retrieveRecording} from "src/store/recording/recordingActions";
import {retrieveActivity} from "src/store/activity/activityActions";
import {ChartContainer, ChartTooltip, ChartTooltipContent} from "src/components/ui/chart";
import {ACTIVITY_RETRIEVE_RESET} from "src/store/activity/activityConstants";
import {RECORDING_RETRIEVE_RESET} from "src/store/recording/recordingConstants";

const levelToStyle = {
  'Entry': 'bg-gray-700 text-gray-100 hover:bg-gray-600 border border-gray-500',
  'Junior': 'bg-green-700 text-green-100 hover:bg-green-600 border border-green-500',
  'Mid': 'bg-yellow-700 text-yellow-100 hover:bg-yellow-600 border border-yellow-500',
  'Senior': 'bg-blue-700 text-blue-100 hover:bg-blue-600 border border-blue-500',
  'Expert': 'bg-indigo-700 text-indigo-100 hover:bg-indigo-600 border border-indigo-500',
};

export function InterviewDetails({ interview }) {
  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString();
  };

  const interviewData = [
    { label: "Interview ID", value: interview.id },
    { label: "Status", value: interview.status.toUpperCase() },
    ...(interview.started_datetime
      ? [
        {
          label: "Started Date & Time",
          value: formatDate(interview.started_datetime),
        },
      ]
      : []),
    ...(interview.ended_datetime
      ? [
        {
          label: "Ended Date & Time",
          value: formatDate(interview.ended_datetime),
        },
      ]
      : []),
    { label: "Interviewer", value: interview.interviewer.name },
    { label: "Job Role", value: interview.metadata.job.role },
  ];

  return (
    <Card className="w-full border-!off-white/10 text-white shadow">
      <CardHeader>
        <CardTitle className="flex items-center gap-2">
          <Presentation className="h-5 w-5 text-gray-400" /> Interview Details
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {interviewData.map((item) => (
            <div key={item.label} className="p-3 rounded-md bg-!off-white/5 border-!off-white/10">
              <p className="font-medium text-white/90">{item.label}</p>
              <p className="text-!off-white/60">{item.value}</p>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
}

function CandidateDetails({ candidate }) {
  const candidateData = [
    { label: "Name", value: candidate.name },
    { label: "Phone", value: candidate.phone },
    { label: "Email", value: candidate.email },
    {
      label: "Resume", value: (
        <a className='hover:text-blue-500 text-blue-400 transition-colors' href={candidate.resume} target="_blank" rel="noopener noreferrer">
          View Resume
        </a>
      )
    },
  ];

  return (
    <Card className="w-full border-!off-white/10 text-white shadow">
      <CardHeader>
        <CardTitle className="flex items-center gap-2">
          <User className="h-5 w-5 text-gray-400" /> Candidate Details
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {candidateData.map((item) => (
            <div key={item.label} className="p-3 rounded-md bg-!off-white/5 border-!off-white/10">
              <p className="font-medium text-white/90">{item.label}</p>
              <p className="text-!off-white/60">{item.value}</p>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
}

export function FeedbackAccordionItem({ feedback }) {
  const categoryToTitle = {
    knowledge: "Knowledge",
    problem_solving: "Problem Solving",
    alignment_with_job: "Alignment with Job",
    communication_skills: "Communication Skills",
    overall: "Overall",
  };

  const title = UTILS.toTitleCase(feedback.category) || feedback.category;

  return (
    <Accordion type="single" collapsible className="w-full">
      <AccordionItem className='border-b border-neutral-700' value={feedback.category}>
        <AccordionTrigger className="flex justify-between py-2 font-medium hover:opacity-90 data-[state=open]:text-gray-100">
          <span className="w-[40%] text-left text-gray-300">{title}</span>
          <Badge
            variant="outline"
            className={cn(
              'ml-auto',
              feedback.score >= "8.00"
                ? "border-green-500 text-green-500"
                : feedback.score >= "6.00"
                  ? "border-yellow-500 text-yellow-500"
                  : "border-red-500 text-red-500"
            )}
          >
            {feedback.score}
          </Badge>
        </AccordionTrigger>
        <AccordionContent className="py-2 text-sm text-gray-400">
          {feedback.comment}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
}

function FeedbackCard(props) {
  const { feedback } = props;

  return (
    <Card className="w-full bg-neutral-900 text-gray-100 shadow">
      <CardHeader>
        <CardTitle className="flex items-center gap-2">
          <MessageCircleReply className="h-5 w-5 text-gray-400" /> Feedback
        </CardTitle>
      </CardHeader>
      <CardContent className="space-y-4">
        {feedback.map(f => (
          <FeedbackAccordionItem key={f.category} feedback={f} />
        ))}
      </CardContent>
    </Card>
  )
}

function VideoRecordingCard(props) {
  const { interview } = props;

  const dispatch = useDispatch();
  const { error, loading, recording } = useSelector(state => state.retrieveRecording)

  useEffect(() => {
    if(error) {
      toast.error(error.message);
    } else if(!recording) {
      dispatch(retrieveRecording(interview.id));
    }
  }, [recording, error]);


  return (
    <Card className="w-full border-!off-white/10 shadow">
      <CardHeader>
        <CardTitle className="flex items-center gap-2">
          <Video className="h-5 w-5 text-gray-400" /> Recording
        </CardTitle>
      </CardHeader>
      <CardContent className="space-y-4 m-auto">
        {
          loading ? (
            <Loader />
          ) : recording ? (
            recording?.status?.toUpperCase() === RECORDING_STATUS.PROCESSED ? (
              <div className='relative aspect-video overflow-hidden rounded-md bg-transparent max-w-2xl mx-auto'>
                <HLSPlayer
                  src={recording?.url}
                  className="absolute inset-0 w-full h-full object-contain" // Or 'object-cover' depending on desired behavior
                />
              </div>
            ) : recording?.status?.toUpperCase() === RECORDING_STATUS.FAILED ? (
              <Alert variant="destructive">
                <AlertCircle className="h-4 w-4" />
                <AlertTitle>Error</AlertTitle>
                <AlertDescription className='text-gray-400'>
                  There was some error processing the recording. Please contact the team to get this resolved.
                </AlertDescription>
              </Alert>
            ) : recording ? (
              <Alert>
                <LoaderCircle className="h-4 w-4 animate-spin" />
                <AlertTitle>Processing!</AlertTitle>
                <AlertDescription className='text-gray-400'>
                  We are still working on the recording. It will be available soon.
                </AlertDescription>
              </Alert>
            ) : (
              <Alert variant="destructive">
                <AlertCircle className="h-4 w-4" />
                <AlertTitle>Error</AlertTitle>
                <AlertDescription className='text-gray-400'>
                  Something went wrong. Please contact the team to get this resolved.
                </AlertDescription>
              </Alert>
            )
          ) : (
            <Alert variant="destructive">
              <AlertCircle className="h-4 w-4" />
              <AlertTitle>Error</AlertTitle>
              <AlertDescription className='text-gray-400'>
                Something went wrong. Please contact the team to get this resolved.
              </AlertDescription>
            </Alert>
          )
        }
      </CardContent>
    </Card>
  )
}


function ActivityCard(props) {
  const { interview } = props;

  const dispatch = useDispatch();
  const { error, loading, activity } = useSelector(state => state.retrieveActivity);

  useEffect(() => {
    if(error) {
      toast.error(error.message);
    } else if(!activity) {
      dispatch(retrieveActivity(interview.id));
    }
  }, [activity, error]);


  const chartConfig = {
    tab_switch: {
      label: "Tab Switch",
      color: "hsl(var(--chart-1))",
    },
  }

  return (
    <Card className="w-full border-!off-white/10 shadow">
      <CardHeader>
        <CardTitle className="flex items-center gap-2">
          <ChartColumnBig className="h-5 w-5 text-gray-400" /> Activity
        </CardTitle>
      </CardHeader>
      <CardContent className="space-y-4 m-auto">
        {
          loading ? (
            <Loader />
          ) : (
            activity?.data ? (
              <ChartContainer config={chartConfig}>
                <BarChart accessibilityLayer data={activity.data}>
                  <CartesianGrid vertical={false} />
                  <XAxis
                    dataKey="stage"
                    tickLine={false}
                    tickMargin={10}
                    axisLine={false}
                    tickFormatter={(value) => value.slice(0, 3)}
                  />
                  <ChartTooltip
                    cursor={false}
                    content={<ChartTooltipContent indicator="dashed" />}
                  />
                  <Bar dataKey="tab_switch" fill={chartConfig.tab_switch.color} radius={4} />
                </BarChart>
              </ChartContainer>
            ) : (
              <Alert variant="destructive">
                <AlertCircle className="h-4 w-4" />
                <AlertTitle>Error</AlertTitle>
                <AlertDescription className='text-gray-400'>
                  Something went wrong. Please contact the team to get this resolved.
                </AlertDescription>
              </Alert>
            )
          )
        }
      </CardContent>
      <CardFooter className="flex-col items-start gap-2 text-sm">
        <div className="flex gap-2 font-medium leading-none">
          Tab Activity <Activity className="h-4 w-4" />
        </div>
        <div className="leading-none text-muted-foreground">
          Showing total tab activity per module during the interview.
        </div>
      </CardFooter>
    </Card>
  )
}

function AudioPlayer(props) {
  const { src, playbackRate = 1.0 } = props;
  const { audioStatus, play, pause, stop } = useAudioPlayer(src, playbackRate);

  return (
    <span
      className={cn('flex bg-!off-white/10 p-2 hover:cursor-pointer hover:bg-!off-white/20 rounded-full w-fit', audioStatus === AUDIO_STATUS.INACTIVE ? 'text-!off-white/60' : (audioStatus === AUDIO_STATUS.PAUSED ? 'text-orange-500' : 'text-green-500'))}
      onClick={() => {
        if (audioStatus === AUDIO_STATUS.INACTIVE || audioStatus === AUDIO_STATUS.PAUSED) play(src);
        else pause();
      }}
    >
      <AudioLines className='h-4 w-4' />
    </span>
  )
}

function DiscussionCard(props) {
  const { title, feedback, discussion } = props;

  return (
    <Card className="w-full border-!off-white/10 shadow">
      <CardHeader>
        <CardTitle className="flex items-center gap-2">
          <FileQuestion className="h-5 w-5 text-gray-400" /> {UTILS.toTitleCase(title)}
        </CardTitle>
      </CardHeader>
      <CardContent className="space-y-4">
        {feedback && (
          <div className='rounded-md p-4 bg-!off-white/5 border-!off-white/10'>
            <div className='flex flex-wrap gap-2 mb-2'>
              <Badge>{`Score: ${feedback.score}/10`}</Badge>
              <Badge className={levelToStyle[feedback.level]}>{feedback.level}</Badge>
            </div>
            <p className='text-sm text-gray-400'>{feedback.detail}</p>
          </div>
        )}
        <div className='space-y-2'>
          {discussion.map((q, idx) => (
            <Accordion key={idx} type="single" collapsible className="w-full">
              <AccordionItem value={'Question ' + (idx + 1)} className="border-b border-!off-white/10">
                <AccordionTrigger className="font-medium hover:opacity-90 data-[state=open]:text-gray-100 hover:no-underline">
                  <span className="text-gray-300">{'Question ' + (idx + 1)}</span>
                </AccordionTrigger>
                <AccordionContent className="py-2 text-gray-400">
                  <div className='space-y-2'>
                    <p className='font-semibold text-gray-200'>{q.question}</p>
                    <div className='rounded-md p-3 bg-!off-white/5'>
                      <p className='whitespace-pre-line text-sm'>{q.answer}</p>
                    </div>
                    {
                      q.audio && (
                        <AudioPlayer key={idx} src={q.audio} />
                      )
                    }
                  </div>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          ))}
        </div>
      </CardContent>
    </Card>
  )
}

export function CodingQuestionTab(props) {
  const { discussion, feedback } = props;

  const validCodingQuestions = discussion.filter(
    (question) => question.question !== null
  );

  if (validCodingQuestions.length === 0) {
    return (
      <Card className="bg-neutral-900 text-gray-100 shadow">
        <CardHeader>
          <CardTitle>No Coding Questions</CardTitle>
        </CardHeader>
        <CardContent>
          <p>There were no coding questions for this interview.</p>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className="w-full border-!off-white/10 shadow">
      <CardHeader>
        <CardTitle className="flex items-center gap-2">
          <Code className="h-5 w-5 text-gray-400" /> Coding
        </CardTitle>
      </CardHeader>
      <CardContent className="space-y-4">
        {feedback && (
          <>
            <div className='rounded-md p-4 bg-!off-white/5 border-!off-white/10'>
              <div className='flex flex-wrap gap-2 mb-2'>
                <Badge>{`Score: ${feedback.score}/10`}</Badge>
                <Badge className={levelToStyle[feedback.level]}>{feedback.level}</Badge>
              </div>
              <p className='text-sm text-gray-400'>{feedback.detail}</p>
            </div>
            <div className='py-2' />
          </>
        )}
        <Tabs defaultValue={validCodingQuestions[0].question} className="w-full mt-4">
          <TabsList className="bg-!off-white">
            {validCodingQuestions.map((question, index) => (
              <TabsTrigger className="data-[state=active]:bg-!black/20 data-[state=active]:text-!black data-[state=inactive]:bg-!off-white/60 data-[state=inactive]:text-!black/60" key={index} value={question.question}>
                Question {index + 1}
              </TabsTrigger>
            ))}
          </TabsList>
          {validCodingQuestions.map((question, index) => (
            <TabsContent key={index} value={question.question}>
              <div className="space-y-4 py-2">
                <h4 className="text-lg font-semibold text-gray-200">Question {index + 1}</h4>
                <p className="whitespace-pre-line text-gray-400">{question.question}</p>
                {question.code && (
                  <div>
                    <Label className="block font-medium mb-1 text-gray-300">Code:</Label>
                    <pre className="rounded-md overflow-x-auto bg-!off-white/5 p-4">
                      <code className="text-sm font-mono text-gray-100">
                          {question.code}
                      </code>
                    </pre>
                  </div>
                )}
                <div className="flex items-center space-x-2">
                  <Badge variant="outline">Hints: {question.hints}</Badge>
                  <Badge variant="outline">Tries: {question.retries}</Badge>
                  {question.correct !== null && (
                    <Badge
                      variant={question.correct ? "outline" : "destructive"}
                      className={cn(question.correct ? "border-green-500 text-green-500 bg-green-900/30" : '')}
                    >
                      {question.correct ? "Correct" : "Incorrect"}
                    </Badge>
                  )}
                </div>
              </div>
            </TabsContent>
          ))}
        </Tabs>
      </CardContent>
    </Card>
  );
}

function InterviewResultScreen(props) {
  const result = DATA.result
  // const interview = DATA.interview

  const { id } = useParams();
  const { error, loading, interview } = useSelector(state => state.retrieveInterview)
  const { error: feedbackError, loading: feedbackLoading, feedback } = useSelector(state => state.retrieveFeedback)
  const { error: downloadError, loading: downloadLoading, success } = useSelector(state => state.downloadInterviewResult)

  const dispatch = useDispatch();

  useEffect(() => {
    if (error) {
      toast.error(error.message)
    } else if (!interview || id !== interview.id) {
      dispatch({
        type: ACTIVITY_RETRIEVE_RESET
      });
      dispatch({
        type: RECORDING_RETRIEVE_RESET
      });
      dispatch(retrieveInterview(id))
    }

    if (feedbackError) {
      toast.error(error.message)
    } else if (!feedback || (interview && id !== interview.id)) { // Ensure interview is loaded before checking its id
      dispatch(retrieveFeedback(id))
    }
  }, [dispatch, error, feedbackError, id, interview, feedback]);

  return (
    <div className="container mx-auto py-6 space-y-8 text-gray-100">
      <div className="flex justify-end items-center">
        <Button
          variant="outline"
          className="space-x-2 text-gray-100 hover:bg-!off-white/10 border-off-white/40"
          disabled={downloadLoading}
          onClick={() => dispatch(dowloadInterviewResult(id))}
        >
          {downloadLoading ? <LoaderCircle className="h-4 w-4 animate-spin" /> : <ArrowDownToLine className="h-4 w-4" />}
          <span>Download Result</span>
        </Button>
      </div>

      {loading ? (
        <Loader />
      ) : interview ? (
        <div className='flex flex-col gap-8'>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            <InterviewDetails interview={interview} />
            <CandidateDetails candidate={interview?.candidate} />
            {/*<VideoRecordingCard recording={interview?.recording} />*/}
          </div>

          {
            interview.status === 'completed' && (
              <div className="grid grid-cols-1 lg:grid-cols-[1.5fr_1fr] gap-8">
                <VideoRecordingCard interview={interview} />
                <ActivityCard interview={interview} />
              </div>
            )
          }
        </div>
      ) : (
        <div></div>
      )}

      {feedbackLoading ? (
        <Loader />
      ) : feedback ? (
        <div className="space-y-8">
          {/* <div className='w-full'>
              <FeedbackCard feedback={feedback.feedback} />
          </div> */}

          {Object.entries(feedback)
            .filter(([key]) => !['feedback', 'coding'].includes(key))
            .map(([key, value], idx) => (
              <DiscussionCard key={key} title={key} discussion={value.discussion} feedback={value.feedback} />
            ))}

          {feedback.coding?.discussion?.length > 0 && (
            <CodingQuestionTab discussion={feedback.coding?.discussion} feedback={feedback.coding?.feedback} />
          )}
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}

export default InterviewResultScreen;