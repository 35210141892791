import React, {useEffect, useState} from 'react';
import {Badge} from "src/components/ui/badge";
import {Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle} from "src/components/ui/card";
import {Separator} from "src/components/ui/separator";
import Button from "src/components/Button";
import {DATA} from "src/data/data";
import {UTILS} from "src/commons/utils";
import {Calendar} from 'src/components/ui/calendar';
import Input from "src/components/Input";
import {CalendarDays, CalendarIcon, ChevronDown, Eye, EyeOff, LoaderCircle, Search} from "lucide-react";
import {Popover, PopoverContent, PopoverTrigger} from "src/components/ui/popover";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator,
  DropdownMenuTrigger
} from "src/components/ui/dropdown-menu";
import {format} from "date-fns";
import {cn} from "src/lib/utils";
import {
  Pagination,
  PaginationContent, PaginationEllipsis,
  PaginationItem, PaginationLink,
  PaginationNext,
  PaginationPrevious
} from "src/components/ui/pagination";
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "src/components/ui/chart";
import {Bar, BarChart, CartesianGrid, XAxis, YAxis} from "recharts"
import Table from "src/components/Table";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {listInterviews, retrieveInterview} from "src/store/interview/interviewActions";
import {retrieveFeedback} from "src/store/feedback/feedbackActions";
import {Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle} from "src/components/ui/sheet";
import Loader from "src/components/Loader";

const formatDateTime = (datetime) => format(datetime, 'dd LLL yyyy HH:MM:SS'); // Localized datetime format

const statusCodes = [
  {
    id: 'pending',
    value: 'Pending',
    className: 'text-orange-600'
  },
  {
    id: 'scheduledd',
    value: 'scheduledd',
    color: 'text-yellow-600'
  },
  {
    id: 'in_progress',
    value: 'In Progress',
    color: 'text-!blue-600'
  },
  {
    id: 'completed',
    value: 'Completed',
    color: 'text-!green-600'
  },
  {
    id: 'incomplete',
    value: 'Incomplete',
    color: 'text-!red-600'
  },
  {
    id: 'cancelled',
    value: 'Cancelled',
    color: 'text-!red-600'
  },
]

function DetailContainer(props) {
  const { title, details } = props;

  return (
    <div className='flex flex-col bg-!grey-400/30 rounded-md p-2 gap-y-2'>
      <div className='flex flex-row w-full text-[1rem] font-extrabold justify-center mt-2 mb-1'>
        {title}
      </div>

      <div className='flex flex-col w-full px-2 gap-y-2 my-2'>
        {
          Object.entries(details).map(([key, value]) => (
            <div key={key} className='flex flex-row'>
            <span className='text-[0.8rem] font-semibold'>
              {UTILS.toTitleCase(key)}:&nbsp;
            </span>

              <span className='text-[0.8rem] justify-start text-start font-normal'>
              {value}
            </span>
            </div>
          ))
        }
      </div>
    </div>
  )
}

const feedbackOrder = [
  "overall_impression",
  "relevance_of_experience",
  "technical_and_soft_skills",
  "accomplishments_and_impact",
  "formatting_and_readability",
  "tips",
  "roast"
];

function SWEInterviewDetailCard(props) {
  const {interview} = props
  const {
    id,
    status,
    type,
    start_datetime,
    end_datetime,
    started_datetime,
    ended_datetime,
    interviewer,
    candidate,
    metadata
  } = interview;



  return (
    <Card className="h-full w-full bg-transparent border-0 rounded-lg text-!off-white">
      {/* Interview Section */}
      <CardHeader>
        <CardTitle className='text-white'>{metadata?.job?.role || 'Interview'}</CardTitle>
        <CardDescription className='text-!off-white'>Status: {status.toUpperCase()}</CardDescription>
      </CardHeader>
      <CardContent>
        <div className='h-full w-full flex flex-col gap-2'>
          <div className='h-full w-full flex lg:flex-row flex-col gap-2'>
            <DetailContainer title="Interview Details" details={{
              type: type.toUpperCase(),
              schedule_start: start_datetime && formatDateTime(start_datetime),
              schedule_end: end_datetime && formatDateTime(end_datetime),
              actual_start: started_datetime && formatDateTime(started_datetime),
              actual_end: ended_datetime && formatDateTime(ended_datetime),
            }} />

            <DetailContainer title="Interviewer Details" details={{
              name: interviewer.name,
              email: interviewer.email,
              role: interviewer.role,
            }} />

            <DetailContainer title="Candidate Details" details={{
              name: candidate.name,
              email: candidate.email,
              phone: candidate.phone,
            }} />
          </div>

          {metadata?.job && (
            <DetailContainer title="Job Details" details={{
              Role: metadata.job.role,
              Description: metadata.job.description,
            }} />
          )}
        </div>
      </CardContent>
    </Card>
  );
}

function InterviewDetailCard(props) {
  const {interview} = props

  return <SWEInterviewDetailCard interview={interview} />
}


const feedbackChartConfig = {
  score: {
    label: "Score",
    color: "#06B6D4",
  },
}

const FeedbackCard = (props) => {
  const dispatch = useDispatch()
  const { error, loading, feedback } = useSelector(state => state.retrieveFeedback);

  const [data, setData] = useState();


  useEffect(() => {
    dispatch(retrieveFeedback(props.interview))
  }, []);

  useEffect(() => {
    if(feedback) {
      let formattedData = []
      feedback.feedback.forEach(f => {
         formattedData.push({
           category: f.category,
           score: parseFloat(f.score),
           comment: f.comment,
         })
      })
      setData(formattedData)
    }
  }, [feedback]);



  return (
    <Card className="h-full w-full bg-transparent border-0 rounded-lg text-!off-white">
      {/* Feedback Section */}
      <CardHeader>
        <CardTitle className='text-white'>Interview Feedback</CardTitle>
        <CardDescription className='text-!off-white'>Detailed review across multiple criteria</CardDescription>
      </CardHeader>
      <CardContent>
        <div className='h-full w-full'>
          {
            !loading && !error && data ? (
              <div className='flex flex-col gap-8'>
                <div className='flex flex-row'>
                  <ChartContainer
                    config={feedbackChartConfig}
                    className="h-full w-full dark"
                  >
                    <BarChart
                      accessibilityLayer
                      data={data}
                      margin={{
                        top: 20,
                      }}
                    >
                      <CartesianGrid vertical={false} />
                      <XAxis
                        dataKey="category"
                        tickLine={false}
                        axisLine={false}
                        tickFormatter={(value) => UTILS.toTitleCase(value)}
                      />
                      {/*<YAxis*/}
                      {/*  dataKey="score"*/}
                      {/*  tickLine={false}*/}
                      {/*  axisLine={false}*/}
                      {/*/>*/}
                      <ChartTooltip
                        content={
                          <ChartTooltipContent
                            className="w-[150px] hover:text-!black"
                            nameKey="score"
                            labelFormatter={(value) => UTILS.toTitleCase(value)}
                          />
                        }
                      />
                      <Bar className='hover:bg-!grey-200' dataKey='score' fill={`var(--blue-600)`} radius={8} />
                    </BarChart>
                  </ChartContainer>
                </div>

                <div className='flex flex-wrap h-full w-full gap-2'>
                  {data.map((f, index) => (
                    <div className='flex lg:w-[48%] h-full'>
                      <DetailContainer
                        title={UTILS.toTitleCase(f.category)}
                        details={{
                          score: f.score,
                          comment: f.comment
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <Loader />
            )
          }
        </div>

      </CardContent>
    </Card>
  );
};


function InterviewSheet(props) {
  const dispatch = useDispatch();
  const { error, loading, interview } = useSelector(state => state.retrieveInterview)
  const { feedbackError, feedbackLoading, feedback } = useSelector(state => state.retrieveFeedback)

  useEffect(() => {
    if(interview == null && props.interview) {
      dispatch(retrieveInterview(props.interview))
    } else if(interview && props.interview && props.interview !== interview.id) {
      dispatch(retrieveInterview(props.interview))
    }
  }, []);


  return (
    <Sheet open={props.open} onOpenChange={(open) => props.onOpenChange(open)}>
      <SheetContent className='bg-!black text-white md:max-w-[65vw] sm:max-w-[80vw] max-w-[100vw] border-!black overflow-auto'>
        <SheetHeader>
          <SheetTitle className='text-white'>Interview Details</SheetTitle>
          {
            loading ? (
              <Loader />
            ) : interview ? (
              <SheetDescription className='text-!off-white'>
                <div className='flex flex-col gap-6'>
                  <div className='flex flex-row'>
                    <InterviewDetailCard interview={interview} />
                  </div>
                  {
                    interview.feedback_status === 'completed' && (
                      <FeedbackCard interview={props.interview} />
                    )
                  }
                </div>
              </SheetDescription>
            ) : (
              <SheetDescription className='text-!off-white'>
                Error
              </SheetDescription>
            )
          }
        </SheetHeader>
      </SheetContent>
    </Sheet>
  )
}


const defaultFilters = {
  page: null,
  search: null,
  status: [],
  scheduled_from: null,
  scheduled_till: null,
}

function InterviewTable(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [statusFilter, setStatusFilter] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [filterParams, setFilterParams] = useState(defaultFilters);
  const [selectedInterview, setSelectedInterview] = useState(null);

  // const { interviews } = DATA;
  const { error, loading, interviews } = useSelector(state => state.listInterviews);

  const interviewTableColumns = [
    {
      id: 'id',
      header: 'Id',
      cell: ({row}) => {
        return <div>{row.id.split('-')[0]}</div>
      },
      enableSorting: false,
      enableHiding: false,
    },
    {
      id: 'created',
      header: 'Created',
      cell: ({row}) => {
        return <div>{format(new Date(row.created), 'dd LLL yyyy HH:MM:SS')}</div>
      },
      enableSorting: false,
      enableHiding: false,
    },
    {
      id: 'candidate',
      header: 'Candidate',
      cell: ({row}) => {
        return <div>{row.candidate || row.original.candidate}</div>
      },

    },
    {
      id: 'status',
      header: 'Status',
      cell: ({row}) => {
        return <Badge>{UTILS.toTitleCase(row.status || row.original.status)}</Badge>
      },
      enableSorting: false,
      enableHiding: false,
    },
    {
      id: 'feedback_status',
      header: 'Feedback',
      cell: ({row}) => {
        return <Badge>{UTILS.toTitleCase(row.feedback_status || row.original.feedback_status)}</Badge>
      },
      enableSorting: false,
      enableHiding: false,
    },
    {
      id: 'action',
      header: 'Action',
      cell: ({row}) => {
        return (
          <div className='flex flex-row'>
           <span className={cn('p-2 hover:bg-!grey-200/20 rounded-md hover:cursor-pointer')}
                 onClick={() => {
                   // setSelectedInterview(row.id)
                   navigate(`/interviews/${row.id}/result`)
                 }}
           >
             <Eye className='h-4 w-4' />
           </span>
          </div>
        )
      }
    }
  ]

  useEffect(() => {
    let params = {}

    for(const key in defaultFilters) {
      if(searchParams.get(key)) {
        if(defaultFilters[key] !== null && typeof defaultFilters[key] === 'object' && searchParams.getAll(key).length > 0) {
          params[key] = searchParams.getAll(key)[0];
        }
        else {
          params[key] = searchParams.get(key);
        }
      }
    }
    setFilterParams({
      ...defaultFilters,
      ...params,
      status: (params.status && params.status.split(',')) || []
    });

    dispatch(listInterviews(params));
  }, [searchParams]);


  const updateUrl = (queryParams) => {
    let params = {
      ...filterParams,
      ...queryParams
    }

    for(const key in defaultFilters) {
      if(UTILS.isNull(params[key]) || params[key].length === 0) {
        delete params[key];
      }
    }

    const urlParams = new URLSearchParams(params);
    if(urlParams.toString().length > 0) {
      navigate('/interviews?' + urlParams.toString());
    }
  }


  const onFilterHandler = (e) => {
    e.preventDefault();
    updateUrl({});
  }

  return (
    <div className='flex flex-col w-full gap-y-4'>
      {
        selectedInterview != null && (
          <InterviewSheet open={true} interview={selectedInterview} onOpenChange={(open) => {
            if(!open) setSelectedInterview(null)
          }} />
        )
      }

      <div className='flex flex-col border-[1px] border-!grey-200/30 p-4 rounded-lg gap-2'>
        <div className='flex flex-row]'>Filters</div>

        <div className='flex flex-row md:w-[40%] w-full gap-2'>
          <div className='flex flex-col w-full'>
            <Input label='search' placeholder='Search using candidates email'
                   onChange={(e) => setFilterParams({
                     ...filterParams,
                     search: e.target.value,
                   })}
                   defaultValue={filterParams.search}
                   hidelabel={true} />
          </div>
          {/*<div className='flex flex-col justify-end'>*/}
          {/*  <Button variant='default'>*/}
          {/*    <Search className='m-auto h-[1.4rem]' />*/}
          {/*  </Button>*/}
          {/*</div>*/}
        </div>

        <div className='flex md:flex-row flex-col gap-2 md:mt-2'>
          <div className='flex flex-col md:w-[30%] w-full'>
            <DropdownMenu modal={false}>
              <DropdownMenuTrigger className='flex flex-row w-full ring-0 outline-none focus:outline-none border-[1px] border-!grey-200/30 h-10 px-4 py-2 rounded-md text-sm text-!grey-200 justify-between' variant='outline'>
                {/*<Button className='ring-0 outline-none focus:outline-none' variant='outline'>Status</Button>*/}
                <span className='flex flex-col'>
                  {
                    (filterParams.status.length === 0) ? (
                      'Status'
                    ) : filterParams.status.length <= 3 ? (
                        filterParams.status.map(status => UTILS.toTitleCase(status)).join(', ')
                    ) : (
                      filterParams.status.map(status => UTILS.toTitleCase(status)).slice(0, 3).join(', ') + '...'
                    )
                  }
                </span>
                <span className='flex flex-col ml-2 h-4 w-4 my-auto'><ChevronDown /></span>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-56 dark">
                <DropdownMenuLabel>Status</DropdownMenuLabel>
                <DropdownMenuSeparator />

                {
                  statusCodes.map(status => (
                    <DropdownMenuCheckboxItem
                      key={status.id}
                      checked={filterParams.status.includes(status.id)}
                      onSelect={(e) => e.preventDefault() }
                      onCheckedChange={() => {
                        const idx = filterParams.status.indexOf(status.id);
                        if(idx >= 0) {
                          let newStatusFilter = filterParams.status;
                          newStatusFilter.splice(idx, 1);
                          setFilterParams({
                            ...filterParams,
                            status: newStatusFilter
                          })
                        } else {
                          setFilterParams({
                            ...filterParams,
                            status: [...filterParams.status, status.id]
                          })
                        }
                      }}
                    >
                      {status.value}
                    </DropdownMenuCheckboxItem>
                  ))
                }

              </DropdownMenuContent>
            </DropdownMenu>
          </div>

          <div className='flex flex-col md:w-[30%] w-full'>
            <Popover>
              <PopoverTrigger>
                <Button
                  id="date"
                  variant={"outline"}
                  className={cn(
                    "w-full justify-start text-left font-normal hover:bg-!black",
                    !filterParams && "text-muted-foreground"
                  )}
                >
                  <CalendarDays className="mr-2 h-4 w-4" />
                  {filterParams?.scheduled_from ? (
                    filterParams.scheduled_till ? (
                      <>
                        {format(new Date(filterParams.scheduled_from), "LLL dd, y")} <span>&nbsp;&rarr;&nbsp;</span>
                        {format(new Date(filterParams.scheduled_till), "LLL dd, y")}
                      </>
                    ) : (
                      format(new Date(filterParams.scheduled_from), "LLL dd, y")
                    )
                  ) : (
                    <span className='text-sm text-!grey-200'>Scheduled between</span>
                  )}
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-auto p-0 dark" align="start">
                <Calendar
                  initialFocus
                  mode="range"
                  defaultMonth={new Date().setMonth(new Date().getMonth() - 1)}
                  disabled={(date) =>
                    date > new Date() || date < new Date("1900-01-01")
                  }
                  selected={{
                    from: filterParams.scheduled_from && new Date(filterParams.scheduled_from),
                    to: filterParams.scheduled_from && new Date(filterParams.scheduled_till)
                  }}
                  onSelect={({ from, to }) => {
                    setFilterParams({
                      ...filterParams,
                      scheduled_from: from && new Date(from.setHours(0, 0, 0, 0)).toISOString(),
                      scheduled_till: to && new Date(to.setHours(0, 0, 0, 0)).toISOString(),
                    })
                  }}
                  numberOfMonths={2}
                />
              </PopoverContent>
            </Popover>
          </div>

          <Button variant='default' type='button' onClick={onFilterHandler}>Apply</Button>
        </div>
      </div>

      <div className='w-full h-full m-auto'>
        <Table columns={interviewTableColumns} data={interviews && interviews.results} loading={loading} />
      </div>

      <div className='w-full h-full border-[0px] border-!grey-200/30 rounded-lg p-2 text-!grey-200'>
        <Pagination>
          {
            interviews && (
              <PaginationContent>
                <PaginationItem>
                  <PaginationPrevious
                    className={cn(
                      'cursor-pointer',
                      UTILS.isNull(interviews.previous) && 'hover:bg-transparent hover:text-!grey-200/60 text-!grey-200/60 cursor-not-allowed'
                    )}
                    onClick={(e) => {
                      e.preventDefault();
                      if(interviews.previous) {
                        updateUrl({
                          page: interviews.page - 1,
                        })
                      }
                    }}
                  />
                </PaginationItem>
                {
                  interviews && interviews.pages > 5 ? (
                    <>
                      {(interviews.page >= 5) && (
                        <PaginationItem>
                          <PaginationEllipsis/>
                        </PaginationItem>
                      )}

                      {
                        UTILS.range(
                          Math.max(1, interviews.page - 3),
                          Math.max(1, interviews.page - 3) + 5
                        ).map((page, index) => (
                          <PaginationItem key={index}>
                            <PaginationLink
                              href='#' isActive={interviews.page === page}
                              onClick={(e) => {
                                e.preventDefault();
                                setFilterParams({
                                  ...filterParams,
                                  page: page,
                                });
                              }}
                            >
                              {page}
                            </PaginationLink>
                          </PaginationItem>
                        ))
                      }

                      {(interviews.page < 5) && (
                        <PaginationItem>
                          <PaginationEllipsis/>
                        </PaginationItem>
                      )}
                    </>

                  ) : interviews && interviews.pages > 0 ? (
                    UTILS.range(1, interviews.pages+1).map((page, index) => (
                      <PaginationItem key={index}>
                        <PaginationLink
                          className='cursor-pointer'
                          isActive={interviews.page === page}
                          onClick={(e) => {
                            e.preventDefault();
                            updateUrl({
                              page: page,
                            })
                          }}
                        >
                          {page}
                        </PaginationLink>
                      </PaginationItem>
                    ))
                  ) : (
                    <PaginationItem>
                      <PaginationEllipsis />
                    </PaginationItem>
                  )
                }

                <PaginationItem>
                  <PaginationNext
                    className={cn(
                      'cursor-pointer',
                      UTILS.isNull(interviews.next) && 'hover:bg-transparent hover:text-!grey-200/60 text-!grey-200/60 cursor-not-allowed'
                    )}
                    onClick={(e) => {
                      e.preventDefault();
                      if(interviews.next) {
                        updateUrl({
                          page: interviews.page + 1,
                        })
                      }
                    }}
                  />
                </PaginationItem>
              </PaginationContent>
            )
          }
        </Pagination>
      </div>
    </div>
  );
}

function InterviewTableCard(props) {
  return (
    <Card className='border-0'>
      <CardHeader>
        <CardTitle>Scheduled Interviews</CardTitle>
        <CardDescription>History of all the scheduled interviews.</CardDescription>
      </CardHeader>

      <Separator className='mb-[4vh] sm:w-[98%] w-full mx-auto' decorative />

      <CardContent>
        <div className='flex flex-col w-full'>
          <div className='flex flex-row'>
            <InterviewTable />
          </div>
        </div>

      </CardContent>

      <CardFooter>

      </CardFooter>
    </Card>
  )
}

function InterviewScreen(props) {
  return (
    <div className='h-full w-full'>
      <InterviewTableCard />
    </div>
  );
}

export default InterviewScreen;
